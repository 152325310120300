import React, { Component, useEffect } from "react";
import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import CallToAction from "./CallToAction/CallToAction";
import Showcase from "./Showcase/Showcase";
import Information from "./Information/Information";
import CookieBar from "@components/shell/CookieBar/CookieBar"
import CookiePopup from "@components/shell/CookiePopup/CookiePopup"
import Modal from "@components/common/Modal/Modal"
import { useCookieData } from "../../../hooks/useCookieData";

import css from "./Footer.module.scss";
import classNames from "classnames";

export class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCookieBarOpen: true,
            isCookiePopupOpen: false,
        };
    }

    closeCookiePopup = () => {
        this.setState({
            isCookiePopupOpen: false,
        });
        document.body.removeAttribute('style');
    }

    openCookiePopup = () => {
        this.setState({
            isCookiePopupOpen: true,
        });
        document.body.style.overflow = "hidden";
        document.body.style.position = "static";
    }

    closeCookieBar = () => {
        this.setState({
            isCookieBarOpen: false,
        });
    }

    checkForCookieConsent(key) {
        const itemStr = localStorage.getItem("cookiesConsented")
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key)
            return null
        }
        return item.value
    }

    render() {
        const { isCookieBarOpen, isCookiePopupOpen } = this.state;
        let consentStatus = "{}"
        if(typeof window !== "undefined"){
        let consent = localStorage.getItem('cookiesConsented');
        this.checkForCookieConsent(consent);
        consentStatus = consent
        }

        return (
            <footer className={classNames(css.footer, "pt-0")}>
                <div className={css.wrapper}>
                    <CallToAction />
                    <ContentWrapper
                        className={css.contentWrapper}
                        isWide={false}
                    >
                        <Showcase />
                        <Information />
                    </ContentWrapper>
                    {/* {isCookiePopupOpen && (<Modal show={true}>
                        <CookiePopup closeCookiePopup={this.closeCookiePopup} /></Modal>
                    )} */}
                </div>
                {/* {!JSON.parse(consentStatus) &&
                isCookieBarOpen && (
                    <CookieBar closeCookieBar={this.closeCookieBar}
                        openCookiePopup={this.openCookiePopup}
                    />
                )} */}
            </footer>
        );
    }
}

export default Footer;
