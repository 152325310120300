import React, { Component } from 'react';
import classNames from 'classnames';
import reactStringReplace from 'react-string-replace';

import css from './RichContent.module.scss';

export class RichContent extends Component {
    constructor(props) {
        super(props)

        this.state = {};
    }

    render() {
        const { children, className } = this.props;

        const search = 'src="/SEPWA/'
        const replace = `src="${process.env.PIMCORE_BASE_URL}/SEPWA/`;
        const replaceResult =  reactStringReplace(children, search, (match, i) => (replace))

        return (
            <div className={classNames(css.container, className)}
                dangerouslySetInnerHTML={{ __html: replaceResult.join("") }}
            />
        );
    }
}

export default RichContent;


