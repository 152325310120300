import { graphql, useStaticQuery } from "gatsby";
import Button from "../../common/Button/Button";
import ToggleSwitch from "../../common/ToggleSwitch/ToggleSwitch";
import { useState } from "react";

import React from "react";
import css from "./CookiePopup.module.scss";


const CookiePopupQuery = (props) => {
    const { pim } = useStaticQuery(graphql`
        query {
            pim {
                getCookiepolicyListing(defaultLanguage: "auto") {
                edges {
                    node {
                        cookiepopupLink
                        cookiepopupConfirm
                        cookieAnalytic
                        cookieAnalyticTitle
                        cookieFunctional
                        cookieFunctionalTitle
                        cookiepopupInfo
                        cookiepopupTitle
                        cookiepopupLinkText
                    }
                }
                }
            }
            }
    `);

    const cookiePopupItems = pim.getCookiepolicyListing.edges[0].node;
    let [functionalChecked, setFunctionalChecked] = useState(true);
    let [analyticChecked, setAnalyticChecked] = useState(true);

    function setConsentOn() {
        const now = new Date()
        const halfyear = 15770000000000
        const item = {
            value: true,
            expiry: now.getTime() + halfyear,
        }
        localStorage.setItem("cookiesConsented", JSON.stringify(item))
    }

    return (
        <div className={`${css.cookiePopupDiv}`}>
            <h1 className={`${css.cookieMainTitle}`}>{cookiePopupItems.cookiepopupTitle}</h1>
            <div className={`${css.cookieSectionDiv}`}>
                <div className={`${css.cookieInfoDiv}`}>
                    <h2 className={`${css.cookieSecondTitle}`}>{cookiePopupItems.cookieFunctionalTitle}</h2>
                    <p className={`${css.cookieText}`}>{cookiePopupItems.cookieFunctional}</p>
                </div>
                <ToggleSwitch id="Functional" checked={functionalChecked} onChange={setFunctionalChecked} disabled />
            </div>

            <div className={`${css.cookieSectionDiv}`}>
            <div className={`${css.cookieInfoDiv}`}>
                    <h2 className={`${css.cookieSecondTitle}`}>{cookiePopupItems.cookieAnalyticTitle}</h2>
                    <p className={`${css.cookieText}`}>{cookiePopupItems.cookieAnalytic}</p>
                </div>
                <ToggleSwitch id="Analytic" checked={analyticChecked} onChange={setAnalyticChecked} />
            </div>

            <div onClick={props.handleClose} className={`${css.cookieButton}`}>
                <Button
                    variant="blue"
                    onClick={() => {props.closeCookiePopup();typeof window !== "undefined" && setConsentOn()}}
                    text={cookiePopupItems.cookiepopupConfirm}
                    uppercase={false} /></div>
            <p className={`${css.cookieInfoText}`} >{cookiePopupItems.cookiepopupInfo}<a target="_blank" rel="noreferrer" className={`${css.cookieLink}`} href={cookiePopupItems.cookiepopupLink}>{cookiePopupItems.cookiepopupLinkText}</a></p>
        </div>
    );
};

export default CookiePopupQuery;
