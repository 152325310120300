import React, { Component } from "react";
import { __ } from "@i18n/util";
import css from "./Webinar.module.scss";

import Heading from "@components/common/Heading/Heading";
import RichContent from "@components/common/RichContent/RichContent";

export class Webinar extends Component {
    render() {
        const {
            contentTitle,
            contentTxt,
            date,
            location,
            forWho,
            program,
        } = this.props;
        return (
            <div className={css.webinar}>
                <div className={css.webinarIntro}>
                    <Heading isSemantic={true} variant="h2">
                        {contentTitle}
                    </Heading>
                    <RichContent>{contentTxt}</RichContent>
                </div>

                <div className={css.webinarBlock}>
                    <div className={css.webinarTitle}>
                        {__("Date and time")}
                    </div>

                    <RichContent>{date}</RichContent>
                </div>

                <div className={css.webinarBlock}>
                    <div className={css.webinarTitle}>{__("Location")}</div>

                    <RichContent>{location}</RichContent>
                </div>
                <div className={css.webinarBlock}>
                    <div className={css.webinarTitle}>{__("Program")}</div>
                    <RichContent className={css.styledList}>{program}</RichContent>
                </div>
                <div className={css.webinarBlock}>
                    <div className={css.webinarTitle}>{__("For who?")}</div>

                    <RichContent>{forWho}</RichContent>
                </div>
            </div>
        );
    }
}

export default Webinar;
