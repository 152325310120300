import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCurrentService, setServiceScrollDirection } from '@redux/actions';

export class ServiceHandler extends Component {
    constructor(props) {
        super(props);

        this.state = {
            canSetNextService: true
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { event, progress, index } = this.props;
        const { setCurrentService, setServiceScrollDirection } = this.props;
        const { canSetNextService } = this.state;

        if (((event.scrollDirection === 'FORWARD' && progress > 0.40 && progress < 1)
            || (event.scrollDirection === 'REVERSE' && progress < 0.60 && progress > 0))
            && canSetNextService) {
            this.setState({ canSetNextService: false });
            setCurrentService(index);
            setServiceScrollDirection(event.scrollDirection);
        }

        if (progress === 0 && !canSetNextService) {
            this.setState({ canSetNextService: true });
        }

        if (progress === 1 && !canSetNextService) {
            this.setState({ canSetNextService: true });
        }
    }

    render() {
        return (
            <div data-trigger={true} />
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setCurrentService: (step) => dispatch(setCurrentService(step)),
    setServiceScrollDirection: (direction) => dispatch(setServiceScrollDirection(direction))
});

const mapStateToProps = ({ currentService }) => ({ currentService });

export default connect(mapStateToProps, mapDispatchToProps)(ServiceHandler);
