/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// This path is relative to the root of the site.
import wrapWithProvider from './src/redux/provider';

export const wrapRootElement = wrapWithProvider;
