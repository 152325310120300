import React, { Component } from "react";

import classNames from "classnames";
import css from "./Menu.module.scss";

import Navigation from "./Navigation/Navigation";
import ContactButtons from "./ContactButtons/ContactButtons";
import { Close, Toggle, MENU_OPEN, MENU_CLOSED } from "./Menu.config";

import { setIsMobileNavigationOpen as setIsMobileNavigationOpenAction } from "@redux/actions";
import { setIsStickyNavigationOpen as setIsStickyNavigationOpenAction } from "@redux/actions";
import { connect } from "react-redux";

export class Menu extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const {
            useDefaultTheme,
            isMobileNavigationOpen,
            isStickyNavigationOpen,
        } = this.props;

        const state = isMobileNavigationOpen ? MENU_OPEN : MENU_CLOSED;
        const type = useDefaultTheme ? "default" : "silhouette";

        if (typeof document !== "undefined") {
            if (
                isMobileNavigationOpen &&
                !document.body.className.match(/(\s|^)is-blurred(\s|$)/g)
            ) {
                document.body.className += " is-blurred";
            } else {
                document.body.className = document.body.className.replace(
                    /(\s|^)is-blurred(\s|$)/g,
                    ""
                );
            }
        }

        return (
            <div
                className={classNames(
                    css.menu,
                    css[type],
                    css[state],
                    typeof window !== "undefined" && {
                        [css.mobileSticky]:
                            !isMobileNavigationOpen &&
                            isStickyNavigationOpen &&
                            window.innerWidth < 1000,
                    }
                )}
            >
                {/* <div className={classNames(css.contactContainer, css[state])}>
                    <ContactButtons />
                </div> */}
                <div
                    role="presentation"
                    className={css.toggle}
                    onClick={() => {
                        this.props.setIsMobileNavigationOpen(true);
                    }}
                >
                    <Toggle />
                </div>
                <div className={css.container}>
                    <div className={css.containerInner}>
                        <div className={css.containerLogo}>
                        </div>
                        <div className={css.closeWrapper}>
                            <div
                                role="presentation"
                                className={css.close}
                                onClick={() => {
                                    this.props.setIsMobileNavigationOpen(false);
                                }}
                            >
                                <Close />
                            </div>
                        </div>
                        <Navigation />
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setIsMobileNavigationOpen: (boolean) =>
        dispatch(setIsMobileNavigationOpenAction(boolean)),
    setIsStickyNavigationOpen: (boolean) =>
        dispatch(setIsStickyNavigationOpenAction(boolean)),
});

const mapStateToProps = (globalState) => ({
    isMobileNavigationOpen: globalState.isMobileNavigationOpen,
    isStickyNavigationOpen: globalState.isStickyNavigationOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
