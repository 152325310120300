import React, { Component} from "react";
import classNames from "classnames";
import css from "./jobCard.module.scss";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import CardContainer from "../CardContainer/CardContainer";

export class JobCard extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const {
            title,
            image,
            imageHover,
            text,
            className,
            variant,
            freeLink
        } = this.props;

        return (
            <div className={classNames(css.jobCard, className, css[variant])}>
                {freeLink 
                ? <Link to={freeLink}><CardContainer title={title} image={image} imageHover={imageHover} text={text}/></Link>                   
                : <CardContainer title={title} image={image} imageHover={imageHover} text={text}/>}
            </div>
        );
    }
}

export default JobCard;
