import React from "react";
import Button from "../Button/Button"
import { useCookieData } from "../../../hooks/useCookieData";


const UniqueContent = (props) => {

    const onClickFunction = () => {
        localStorage.removeItem('cookiesConsented');
        cookieData.openCookiePopupFunction();
        cookieData.closeCookiebarFunction()
    }

    const {cookieData, update} = useCookieData();

    return (
        props.pageReference === "Cookiepolicy" &&
        <Button
            link="#"
            variant="blue"
            marginTop
            onClick={onClickFunction}
            text={cookieData.buttonText}
            uppercase={false} />
    );
}

export default UniqueContent;
