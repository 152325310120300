import React, { Component } from "react";

import Links from "./Links/Links";
import Copyright from "./Copyright/Copyright";

import css from "./Information.module.scss";

export class Information extends Component {
    render() {
        return (
            <div className={css.container}>
                <Links />
                <Copyright />
            </div>
        );
    }
}

export default Information;
