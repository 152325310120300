import React, { Component } from 'react'

import css from "./Quote.module.scss";
import classNames from "classnames";

export class Quote extends Component {
    render() {
        const { size, color, quote, quoteBy, quoteByFunction } = this.props;

        return (
            <blockquote className={classNames(css.quote, css[size], css[color])}>
                <div className={css.quoteText}>
                    {quote}
                </div>
                <footer className={classNames(css[color])}>
                    {quoteBy && <p className={css.quoteBy}>{quoteBy}</p>}
                    {quoteByFunction && <p className={css.quoteFunction}>{quoteByFunction}</p>}
                </footer>
            </blockquote>
        )
    }
}

export default Quote
