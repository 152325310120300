import React, { Component } from "react";
import classNames from "classnames";
import css from "./ColumnGroup.module.scss";

export class ColumnGroup extends Component {
    render() {
        const {
            innerRef,
            children,
            type,
            halign,
            valign,
            wrap,
            className,
        } = this.props;

        return (
            <div
                ref={innerRef}
                className={classNames(
                    css.columnGroup,
                    css[type],
                    css[halign],
                    css[valign],
                    css[wrap],
                    className
                )}
            >
                {children}
            </div>
        );
    }
}

export const ForwardedColumnGroup = React.forwardRef((props, ref) => (
    <ColumnGroup {...props} innerRef={ref} />
));

export default ColumnGroup;
