import React, { Component } from "react";
import { LocalizedLink as Link } from "gatsby-theme-i18n";

import classNames from "classnames";

import { __ } from "@i18n/util";
import css from "./Blog.module.scss";

export class Blog extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const {
            url,
            image,
            labels,
            title,
            text,
            author,
            date,
            className,
            variant
        } = this.props;


        // eslint-disable-next-line no-unused-vars
        const [day, ...rest] = date.split(" ");

        return (
            <div className={classNames(css.blog, css[variant], className)}>
                <Link to={url}>
                    <div className={classNames(css.image)}>
                        <div className={classNames(css.imageContainer)}>
                            <div className={classNames(css.imageWrapper)}>
                                <img alt={title} loading="lazy" src={image} />
                            </div>
                        </div>
                    </div>


                    <div className={css.content}>

                        <div className={classNames(css.content)}>
                            {labels && labels.length > 0 && (
                                <div className={classNames(css.labels)}>
                                    {labels.map((label, index) => (
                                        <div key={index} className={classNames(css.label)}>
                                            {label}
                                        </div>
                                    ))}
                                </div>
                            )}

                            {title && (
                                <div className={classNames(css.title)}>{title}</div>
                            )}
                            {text && (
                                <div className={classNames(css.text)}>{text}</div>
                            )}

                            {(author || date ) && (
                                <div className={css.footer}>
                                    {author && (
                                        <div className={classNames(css.author)}>
                                                    {__("By")}
                                                    {` ${author}`}
                                        </div>
                                    )}
                                    {date && (
                                        <div className={classNames(css.date)}>
                                            {day}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}

export default Blog;
