import { generateLayout } from "@util/generate-layout";

import React, { Component } from "react";
import css from "./CtaBlock.module.scss";
import classNames from "classnames";
import Heading from "@components/common/Heading/Heading";
import urlBuilder from "@util/url-builder";
import LinkButton from "@components/common/LinkButton/LinkButton";
import { __ } from "@i18n/util";

export class CtaBlock extends Component {
    render() {
        const { title, titleSize, text, email, telephone, backgroundImage } = this.props;
        const emailLink = `mailto:${email}`;
        const telephoneLink = `tel:${telephone}`;

        const layout = generateLayout({
            backgroundImage
        });

        const size = (titleSize) ? titleSize : "h3"

        let btnColor = (backgroundImage) ? "orange" : "white"

        return (
            <div className={css.ctaBlock}>
                <div className={classNames(css.container, css[layout.backgroundImage])}>
                    {title && (
                        <Heading
                            variant={size}
                            color="white"
                            className={css.title}
                        >
                            {title}
                        </Heading>
                    )}
                    <div dangerouslySetInnerHTML={{ __html: text }}></div>
                    {
                        /*
                            <LinkButton
                                link={urlBuilder.buildStaticUrl("/vacancy-contact")}
                                variant="orange"
                                text={__("Apply now!")}
                                uppercase={false}
                                marginSides
                            />
                            <LinkButton
                                link={urlBuilder.buildStaticUrl("/vacancy-letter")}
                                variant="orange"
                                text={__("Stay up to date with our job vacancies")}
                                uppercase={false}
                                marginSides
                            />
                        */
                    }
                    <div></div>
                </div>
            </div>
        );
    }
}

export default CtaBlock;
