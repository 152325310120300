import { __ } from "@i18n/util";
import classNames from "classnames";
import React, { Component, Fragment } from "react";
import css from "./BlogGrid.module.scss";



export class BlogGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requestedMore: 1,
        };
    }

    onLoadMore = () => {
        this.setState((prevState) => {
            return {
                requestedMore: prevState.requestedMore + 1,
            };
        });
    };

    render() {
        const { children, filter, className } = this.props;

        const limit = filter ? filter * this.state.requestedMore : 100;
        const count = React.Children.count(children);

        return (
            <Fragment>
                <div className={classNames(css.grid, className)}>
                    {React.Children.map(children, (child, index) => {
                        if (index < limit) {
                            return React.cloneElement(child, {
                                className: css.item,
                            });
                        }
                    })}
                </div>

                {
                    (filter && limit < count) &&
                    (<div className={css.btnContainer}><div role="button" className={css.button} tabIndex="-1" onClick={this.onLoadMore} onKeyDown={this.onLoadMore} >{ __('Load more') }</div></div>)
                }
            </Fragment>
        );
    }
}

export default BlogGrid;
