import React from 'react'
import { graphql, useStaticQuery } from "gatsby";
import css from './ServicesServiceGrid.module.scss';
import urlBuilder from "@util/url-builder";
import Heading from "@components/common/Heading/Heading";
import FeaturedService from '../FeaturedService/FeaturedService';

function FeaturedServiceGrid() {
    const { pim } = useStaticQuery(graphql`
    {
        pim {
            getHomeConfig(id: 157, defaultLanguage: "auto") {
                onzedienstenssectiontitle
                onzedienstensectiontext
                featuredservices {
                    ... on PIM_object_FeaturedService {
                        id
                        logo{
                            id
                            fullpath
                        }
                        listingTitle
                        listingtext
                        URL
                    }
                }
            }
        }
    }
`);

    const { onzedienstenssectiontitle, onzedienstensectiontext } = pim.getHomeConfig;

    return (
        <div className={css.featuredServiceGrid}>
            <Heading
                isSemantic={true}
                variant="h2"
            >
                {onzedienstenssectiontitle}
            </Heading>
            <div>
                {onzedienstensectiontext}
            </div>
            <div className={css.featuredServiceGridItems}>
                {
                    pim.getHomeConfig.featuredservices.map(
                        ({ id, logo, listingTitle, listingtext, URL }) =>
                            (
                                <FeaturedService
                                    key={id}
                                    logo={logo}
                                    title={listingTitle}
                                    listingtext={listingtext}
                                    url={urlBuilder.buildStaticUrl(URL ?? "")}
                                />
                            )
                    )
                }
            </div>
        </div>
    )
}

export default FeaturedServiceGrid
