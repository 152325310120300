export const setCurrentService = (currentService) => ({
    type: 'SET_CURRENT_SERVICE',
    currentService
});

export const setServiceScrollDirection = (direction) => ({
    type: 'SET_SERVICE_SCROLL_DIRECTION',
    direction
});

export const setMeta = (meta) => ({
    type: 'SET_META',
    meta
});

export const setPages = (pages) => ({
    type: 'SET_PAGES',
    pages
});

export const setLocale = (locale) => ({
    type: 'SET_LOCALE',
    locale
});

export const setIsMobileNavigationOpen = (setIsMobileNavigationOpen) => ({
    type: 'SET_IS_MOBILE_NAVIGATION_OPEN',
    setIsMobileNavigationOpen
}); 

export const setIsStickyNavigationOpen = (setIsStickyNavigationOpen) => ({
    type: 'SET_IS_STICKY_NAVIGATION_OPEN',
    setIsStickyNavigationOpen
}); 