import { parseAndDispatchMeta } from "../util/dispatch";
import useLocale from "./useLocale";

export const useStaticQueryMiddleware = (data, options) => {
    const localizedData = useLocale(data, options);

    if (options && options.query) {
        parseAndDispatchMeta(localizedData, options);
    }

    return localizedData;
}

export default useStaticQueryMiddleware;