import { setCurrentService  as setCurrentServiceAction} from '@redux/actions'; 
import classNames from 'classnames';
import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { connect } from 'react-redux';
import { ACTIVE_STATE_CLASS, DEFAULT_STATE_CLASS } from './Indicator.config';
import css from './Indicator.module.scss';




export class Indicator extends Component {
    constructor(props) {
        super(props);

        this.state = { };
    }

    onStepClick = (index) => {
        this.props.setCurrentServiceProp(index);
    }

    renderSteps() {
        const { currentService } = this.props;
        const steps = [];

        for (let index = 1; index <= 6; index++) {
            const state = (index - 1 <= currentService) ? ACTIVE_STATE_CLASS : DEFAULT_STATE_CLASS;

            steps[index] = (
                <AnchorLink
                    href={`#service_${index}`}
                    key={index}
                    className={classNames(css.step, css[state])}
                    style={{ top: `${(index / 7) * 100}%` }}
                    onClick={this.onStepClick.bind(this, index - 1)}
                />
            );
        }
        return steps;
    }

    render() {
        const { currentService } = this.props;

        return (
            <div className={css.indicator}>
                <div
                    className={css.progress}
                    style={{ height: `${((currentService + 1) / 7) * 100}%` }}
                />
                <div className={css.bar} />
                { this.renderSteps() }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({ 
    setCurrentServiceProp: (step) => dispatch(setCurrentServiceAction(step)) 
}); 

const mapStateToProps = ({ currentService }) => ({ currentService }); 

export default connect(mapStateToProps, mapDispatchToProps)(Indicator); 
