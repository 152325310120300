import React from 'react'
import classNames from 'classnames'
import css from "@styles-pages/softwareCard.module.scss";
import ImageGrid from './ImageGrid';
import RichContent from '../common/RichContent/RichContent';

export class SoftwareCard extends React.Component {
    render() {

        const { images, text, footer, logos } = this.props;

        return (
            <>
                <div className={classNames(css.cardWrapper)}>
                    <RichContent className={classNames(css.cardText)}>
                        {text}
                    </RichContent>

                    <ImageGrid imageObject={images} />

                    <RichContent className={classNames(css.cardFooter)}>
                        {footer}
                    </RichContent>
                </div>

            </>
        );
    }
}

export default SoftwareCard;