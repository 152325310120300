import React, { Component, Fragment } from "react";

import classNames from "classnames";
import css from "./Header.module.scss";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import Fade from '@components/util/Fade';

import { DEFAULT_TYPE_CLASS, IMAGE_TYPE_CLASS, FALLBACK_IMAGE, FALLBACK_COLOR } from "./Header.config";

export class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { image, size, bgColor, bgImage, children } = this.props;
        const type =
            image !== undefined ? IMAGE_TYPE_CLASS : DEFAULT_TYPE_CLASS;

        // const imageUrl =
        //     type === "image" && typeof image == "boolean" ? {} : image;

        // const headerImage = `url(${imageUrl})`;

        const imageUrl = (bgImage)
            ? generateThumbnailUrl(bgImage, 'header_background_thumbnail')
            : FALLBACK_IMAGE

        const headerImage = `url(${imageUrl})`;

        const backgroundColor = (bgColor) ? bgColor : FALLBACK_COLOR

        return (
            <Fragment>

                <div
                    className={classNames(css.header, css[type], css[size])}
                >

                    {/* posted text erea */}

                    {React.cloneElement(children, {
                        className: css.zIndex
                    })
                    }
                    {/* end posted text erea  */}

                    <div className={css.bgFilterOverlay} ></div>
                </div>

            </Fragment>
        );
    }
}

export default Header;
