import React, { Component } from "react";
import css from "./NewsMeta.module.scss";

export class NewsMeta extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { date, author } = this.props;
        // We use spreading to get the first element
        // eslint-disable-next-line no-unused-vars
        const [day, ...rest] = date.split(" ");

        return (
            <div className={css.meta}>
                {" "}
                {day} | {author}{" "}
            </div>
        );
    }
}

export default NewsMeta;
