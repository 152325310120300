import React from "react";
import classNames from "classnames";
import css from "@components/common/JobCard/jobCard.module.scss";

const CardContainer = (props) => {
    return (
            <div className={css.jobCardContainer}>
                <div className={css.jobCardWrapper}>
                    <div className={classNames(css.imageContainer)}>
                        <img alt={props.title} loading="lazy" src={props.image} />
                        <img
                            className={css.portraitHover}
                            loading="lazy"
                            src={props.imageHover}
                            alt={props.title}
                        />
                    </div>
                    <div className={classNames(css.content)}>
                        <div className={classNames(css.inner)}>
                            {props.title && (
                                <div className={classNames(css.title)}>{props.title}</div>
                            )}
                            {props.text && (
                                <div className={classNames(css.text)}>{props.text}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default CardContainer;
