import { ContentWrapper, ForwardedContentWrapper } from "@components/common/ContentWrapper/ContentWrapper";
import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Controller, Scene } from "react-scrollmagic";
import Indicator from "./Indicator/Indicator";
import ServiceController from "./ServiceController/ServiceController";
import css from "./ServicesList.module.scss";


export class ServicesList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            calculatedHeight: false
        };

        this.servicesRef = React.createRef();
    }

    renderServices() {
        const { items } = this.props;
        const { length } = items;

        if (length > 0) {
            return (
                <React.Fragment>
                    {items.map((item, index) => this.renderService(item, index, length))}
                </React.Fragment>
            );
        }

        return <React.Fragment />;
    }

    renderService(service, index, total) {
        const { title, subtitle, richContent } = service;
        const position = index + 1;

        return (
            <ServiceController
                key={index}
                position={position}
                total={total}
                richContent={richContent}
                title={title}
                subtitle={subtitle}
            />
        );
    }

    componentDidMount() {
        this.node = this.servicesRef.current;
        this.duration = (this.node && window) ? this.node.scrollHeight - window.innerHeight : 0;

        if (!this.state.calculatedHeight) {
            this.forceUpdate();
            this.setState({calculatedHeight: true});
        }
    }

    render() {
        return (
            <ContentWrapper className={classNames(css.list)}>
                <div className={css.overlay} style={{height:`${(this.node) ? this.node.scrollHeight + "px" : "350vh"}`}}>
                    <Controller>
                        <Scene
                            duration={`${(this.node) ? this.duration + "px" : "600vh"}`}
                            pin={true}
                            indicators={false}
                            enabled={true}
                            triggerHook={0}
                        >
                            <div>
                                <Indicator />
                            </div>
                        </Scene>
                    </Controller>
                </div>
                <ForwardedContentWrapper ref={this.servicesRef} className={classNames(css.contentWrapper, css.services)}>
                    { this.renderServices() }
                </ForwardedContentWrapper>
            </ContentWrapper>
        );
    }
}

const mapStateToProps = ({ currentService }) => ({ currentService });

export default connect(mapStateToProps, null)(ServicesList);
