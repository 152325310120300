import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// reviews.css adresses classes slick (slider) uses to add margin between items!
// cant seem to be done with css modules it seems
import "./reviews.css"

// Use heading module inside this component or on index page itself?
import Heading from "@components/common/Heading/Heading";

import css from "./ReviewFader.module.scss";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";

import classNames from "classnames";


export class ReviewFader extends React.Component {

    render() {

        const { data, width, children } = this.props;

        const settings = {
            dots: false,
            arrows: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 4000,
            cssEase: "linear",
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 830,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 510,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }]
        };
        return (
            <div className={css.container}>

                <Heading
                    isSemantic={true}
                    variant="h2"
                    color="black"
                    // using class title to get text centered!
                    className={css.title}
                >
                    Reviews
                </Heading>

                <div className={css.wrapper}>
                    <Slider {...settings}>
                        {data.map((item) => {
                            return (
                                //clipping fixed implimented styling
                                <div className={css.reviewContainer} key={item}>

                                    <div className={css.reviewStars}>
                                        {/* Make Array with item.start times "slots", use Spread syntac(...) to fill with undefinded, so we can loop over it */}
                                        {[...Array(item.stars)].map(() => {
                                            return <img className={css.star} src="/assets/images/star.svg" alt="Star icon" />
                                        })}
                                    </div>
                                    <div className={css.reviewText} dangerouslySetInnerHTML={{ __html: item.text}}>
                                        
                                    </div>
                                    <div className={css.reviewFlex}>
                                        <div className={css.reviewAvatar}>

                                            <img className={css.avatarImg}  src={generateThumbnailUrl(
                                                item.image,
                                                "carousel_thumbnail"
                                            )} alt="Profile picture" />
                                        </div>
                                        <div className={css.reviewTitleContainer}>
                                            <div className={css.reviewTitle}>
                                                {item.title}
                                            </div>
                                            <div className={css.reviewSubtitle}>
                                                {item.subtitle}
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            )
                        })}

                    </Slider>
                </div>
            </div>
        );
    }

}

export default ReviewFader;
