import React, { Component } from 'react'
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import { generateLayout } from '@util/generate-layout';
import VideoPlayer from "@components/common/VideoPlayer/VideoPlayer";
import RichContent from "@components/common/RichContent/RichContent";

import classNames from "classnames";
import css from './Article.module.scss';

export class Article extends Component {
    render() {
        const { reverse, title, text, mediaSrc, type, children } = this.props

        const layout = generateLayout({
            reverse
        });

        const mediaBlock = (type === 'video')
            ? <VideoPlayer videoLink={mediaSrc} />
            : <img alt={title} loading="lazy" src={generateThumbnailUrl(mediaSrc, "general_thumbnail")} />;

        return (
            <div className={classNames(css.article, css[layout.reverse])}>
                <div className={css.articleText}>
                    <h2>{title}</h2>
                    <RichContent>
                        {text}
                    </RichContent>
                    <div className={css.articleButton}>
                        {children}
                    </div>
                </div>
                {mediaSrc
                    ? <div className={css.articleMedia}>
                        {mediaBlock}
                    </div>
                    : ""}
            </div>
        )
    }
}

export default Article
