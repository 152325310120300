import React, { Fragment } from "react";
import css from "@styles-pages/index.module.scss";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import RichContent from "@components/common/RichContent/RichContent";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import ColumnGroup from "@components/common/ColumnGroup/ColumnGroup";
import Column from "@components/common/Column/Column";
import Webinar from "@components/common/Webinar/Webinar";
import { graphql } from "gatsby";
import { ServerSideMetaTags } from "../components/util/SOE/SOE";

/* eslint-disable no-undef */
export const metadata = {
    query: 'getWebinar',
    title,
    description,
    image,
    structureddata: "stucturedData"
};


const Page = (props) => {
    const { data } = props;

    const {
        PageTitle,
        PageSubTitle,
        ContentTitle,
        ContentTxt,
        Dateandtime,
        Location,
        Forwho,
        ContactForm,
        stucturedData,
        Program
    } = data.pim.getWebinar;

    return (
        <Fragment>
            <ServerSideMetaTags pim={data.pim} metadata={metadata} structureddata={stucturedData}/>
            <ContentWrapper isWide={true}>
                <Header image={true} size="oneLiner">
                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                    >
                        <ContentWrapper
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >
                            <Heading
                                isSemantic={true}
                                variant="h1"
                                color="white"
                            >
                                {PageTitle}
                            </Heading>
                            <HighlightedText color="white">
                                <RichContent>{PageSubTitle}</RichContent>
                            </HighlightedText>
                        </ContentWrapper>
                    </ContentWrapper>
                </Header>

                <ContentWrapper maxWidth="maxWidthLarge" paddedTop paddedBottom>
                    <ColumnGroup halign="halignbetween">
                        <Column widthmd="widthsm100" widthlg="widthmd40">
                            <Webinar
                                contentTitle={ContentTitle}
                                contentTxt={ContentTxt}
                                date={Dateandtime}
                                location={Location}
                                forWho={Forwho}
                                program={Program}
                            />
                        </Column>
                        <Column widthmd="widthsm100" widthlg="widthmd50">
                            <RichContent>
                                {ContactForm}
                            </RichContent>
                        </Column>
                    </ColumnGroup>
                </ContentWrapper>
            </ContentWrapper>
        </Fragment>
    );
};

export default Page;

export const query = graphql`
    query($id: Int!, $hrefLang: String!) {
        pim {
            getWebinar(id: $id, defaultLanguage: $hrefLang) {
                PageTitle
                PageSubTitle
                ContentTitle
                ContentTxt
                Dateandtime
                Location
                ContactForm
                Forwho
                seotitle
                seodescription
                stucturedData
                Program
            }
        }
    }
`;
