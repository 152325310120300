import css from "@styles-pages/vacancy-post.module.scss";

import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import CtaBlock from "@components/vacancies/CtaBlock/CtaBlock";
import ImageSlider from "@components/vacancies/ImageSlider/ImageSlider";
import InformationGrid from "@components/common/InformationGrid/InformationGrid";
import LinkButton from "@components/common/LinkButton/LinkButton";
import LinkButtonRow from "@components/common/LinkButtonRow/LinkButtonRow";
import Benefits from "@components/common/Benefits/Benefits";
import VideoPlayer from "@components/common/VideoPlayer/VideoPlayer";
import VacancyList from "@components/vacancies/VacancyList/VacancyList";
import urlBuilder from "@util/url-builder";
import { __ } from "@i18n/util";
import { useTranslation, Translation } from "react-i18next";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { checkButton } from '@util/check-button';
import { ServerSideMetaTags } from "../components/util/SOE/SOE";
import Fade from '@components/util/Fade';
import AnchorLink from "react-anchor-link-smooth-scroll";



/* eslint-disable no-undef */
export const metadata = {
    query: 'getVacanciesListing',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const VacancyListQuery = () => {
    const { pim } = useStaticQuery(graphql`
        query {
            pim {
                getVacancyListing(defaultLanguage: "auto") {
                    edges {
                        node {
                            id
                            functiontitle
                        }
                    }
                }
                getVacanciesListing(defaultLanguage: "auto") {
                    edges {
                        node {
                            seotitle
                            seodescription
                            stucturedData
                            ogimage {
                                id
                                fullpath
                            }
                            solliciteernuCTAtitle
                            solliciteerspontaanCTA
                            email
                            telephone
                            name
                            occupation
                            portrait {
                                id
                                fullpath
                            }
                            testimonial
                            testimonialtitle
                            id
                            caroussel {
                                image {
                                    id
                                    fullpath
                                }
                            }

                            button1URL
                            button1txt
                            button2URL
                            button2txt

                            blocktitle
                            BottomLeftText
                            BottomLeftTitle
                            BottomMiddleText
                            BottomMiddleTitle
                            CarOfferedText
                            CarOfferedTitle
                            NoCarOfferedText
                            NoCarOfferedTitle

                            benefitsTitle
                            ourBenefits

                            VideoLink
                            TopLeftText
                            TopLeftTitle
                            TopMiddleText
                            TopMiddleTitle
                            TopRightText
                            TopRightTitle
                            vacancy {
                              ... on PIM_object_Vacancy {
                                id
                                functiontitle
                              }
                            }
                        }
                    }
                }
            }
        }
    `);

    const { t, i18n } = useTranslation();

    const vacancies = pim.getVacanciesListing.edges[0].node.vacancy;
    const config = pim.getVacanciesListing.edges[0].node;




    const vacancyListAnchor = "onze_vacatures";
    const informationGridAnchor = "waarom_studio_emma";
    const benefitsAnchor = "onze_benefits";

    const jobsLinks = [
        [t("Onze vacatures"), `#${vacancyListAnchor}`],
        [t("Waarom Studio Emma"), `#${informationGridAnchor}`],
        [t("Onze benefits"), `#${benefitsAnchor}`],
        ];

    const jobsLinksList = jobsLinks.map((item) =>({
        title: item[0],
        anchor: item[1]
    }));


    // CTA
    const { solliciteerspontaanCTA, solliciteernuCTAtitle, email, telephone, stucturedData } = config;

    // Caroussel
    const imageObjects = config.caroussel.map((e) => e.image);


    // Information grid
    const {
        blocktitle,
        TopRightTitle,
        TopRightText,
        TopMiddleTitle,
        TopMiddleText,
        TopLeftTitle,
        TopLeftText,
        BottomLeftTitle,
        BottomLeftText,
        BottomMiddleTitle,
        BottomMiddleText,
        NoCarOfferedTitle,
        NoCarOfferedText,

        benefitsTitle,
        ourBenefits,

        VideoLink,
        button1URL,
        button1txt,
        button2URL,
        button2txt
    } = config;

    const isBtn1 = checkButton(button1txt, button1URL);
    const isBtn2 = checkButton(button2txt, button2URL);


    return (
        <>
            <ServerSideMetaTags pim={pim} metadata={metadata} structureddata={stucturedData}/>
            <ContentWrapper isWide={true}>
                <Header image={true} size="oneLiner">
                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                    >
                        <ContentWrapper
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >
                            <Heading isSemantic={true} variant="h1" color="white">
                                {__("Working at Studio Emma?")}
                            </Heading>
                            <HighlightedText color="white">
                                {__`Do you want to work in a company that recognizes your talent and uses it to fully realize your ambition? Do you like to surround yourself with a team of expert and committed people? In short, are you the person who wants to make a difference?`}
                            </HighlightedText>

                            {(isBtn1 || isBtn2) && (
                                <LinkButtonRow>
                                    {isBtn1 && (
                                        <LinkButton
                                            link={urlBuilder.buildStaticUrl(button1URL)}
                                            variant="orangeRevert"
                                            text={button1txt}
                                        />
                                    )}

                                    {isBtn2 && (
                                        <LinkButton
                                            link={urlBuilder.buildStaticUrl(button2URL)}
                                            variant="orangeRevert"
                                            text={button2txt}
                                        />
                                    )}
                                </LinkButtonRow>
                            )}
                        </ContentWrapper>

                        <ContentWrapper
                            isWide={true}
                            className={css.anchorLinks}
                        >
                            {jobsLinksList.map(({ title, anchor }, index) => (
                                <AnchorLink
                                    className={css.anchorLink}
                                    href={anchor}
                                    key={index}
                                >
                                    {title}
                                </AnchorLink>
                            ))}
                        </ContentWrapper>

                    </ContentWrapper>
                </Header>
                <Fade duration={2000}>
                    <ContentWrapper
                        isWide={true}
                        paddedTop={true}
                        paddedBottom={true}
                    >
                        <VacancyList vacancies={vacancies} anchor={vacancyListAnchor} />
                    </ContentWrapper></Fade>
                <Fade duration={2000}>
                    <ContentWrapper isWide={true} className={css.informationGrid} paddedTop paddedBottom>
                        <ContentWrapper>
                            <InformationGrid
                                title={blocktitle}
                                anchor={informationGridAnchor}
                                items={[
                                    { title: TopLeftTitle, text: TopLeftText },
                                    { title: TopMiddleTitle, text: TopMiddleText },
                                    { title: TopRightTitle, text: TopRightText },
                                    {
                                        title: BottomLeftTitle,
                                        text: BottomLeftText,
                                    },
                                    {
                                        title: BottomMiddleTitle,
                                        text: BottomMiddleText,
                                    },
                                    {
                                        title: NoCarOfferedTitle,
                                        text: NoCarOfferedText,
                                    },
                                ]}
                            />
                        </ContentWrapper>
                    </ContentWrapper></Fade>
                <Fade duration={2000}>
                    <ContentWrapper maxWidth="maxWidthMedium" isWide={true}>
                        <ContentWrapper paddedTop={true} paddedBottom={true}>
                            <Benefits
                            title={benefitsTitle}
                            text={ourBenefits}
                            anchor={benefitsAnchor} />
                        </ContentWrapper>
                    </ContentWrapper></Fade>
                    {VideoLink && <Fade duration={2000}>
                    <ContentWrapper maxWidth="maxWidthMedium" isWide={true}>
                        <ContentWrapper paddedTop={true} paddedBottom={true}>
                            <VideoPlayer videoLink={VideoLink} />
                        </ContentWrapper>
                    </ContentWrapper></Fade> }
                
                <ContentWrapper
                    isWide={true}
                    paddedTop={true}
                >
                    <ImageSlider imageObjects={imageObjects} />
                </ContentWrapper>
                <CtaBlock
                    title={solliciteernuCTAtitle}
                    text={solliciteerspontaanCTA}
                    email={email}
                    telephone={telephone}
                />
            </ContentWrapper>
        </>
    );
};

const Page = () => {
    return <VacancyListQuery />;
};

export default Page;
