import React, { useContext, useState } from 'react'

const CookieDataContext = React.createContext()
const fallbackText = "Cookies instellen"
const fallbackFunction = () => {}

export const CookieDataProvider = ({ children }) => { 
  const [cookieData, setCookieData] = useState({buttonText: fallbackText, openCookiePopupFunction: fallbackFunction, closeCookiebarFunction: fallbackFunction})

  return (
    <CookieDataContext.Provider value={{ cookieData, update: setCookieData }}>
      {children}
    </CookieDataContext.Provider>
  )
}

export const useCookieData = () => {
  const { cookieData, update } = useContext(CookieDataContext)
  return { cookieData, update }
}