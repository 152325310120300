import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import RichContent from "@components/common/RichContent/RichContent";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import { __ } from "@i18n/util";
import { graphql } from "gatsby";
import React, { Fragment } from "react";
import css from "@styles-pages/index.module.scss";
import labelcss from "@styles/label.module.scss";

import { ServerSideMetaTags } from "../components/util/SOE/SOE";

/* eslint-disable no-undef */
export const metadata = {
    query: 'getProject',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const Page = (props) => {
    const { data } = props;

    const {
        Resultaat: ResultRichContent,
        Oplossing: SolutionRichContent,
        Uitdaging: ChallengeRichContent,
        projectpagetitle,
        projectintroduction,
        backgroundimage,
        stucturedData,
        Tags: labels
    } = data.pim.getProject;

    return (
        <Fragment>
            <ServerSideMetaTags pim={data.pim} metadata={metadata} structureddata={stucturedData} />
            <ContentWrapper isWide={true}>
                <Header image bgImage={backgroundimage} size="oneLiner">
                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                    >
                        <ContentWrapper
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >
                            <Heading isSemantic={true} variant="h1" color="white">
                                {projectpagetitle}
                            </Heading>
                            <HighlightedText color="white">
                                {projectintroduction}
                            </HighlightedText>
                            {labels && labels.length > 0 && (
                                <div className={labelcss.labelDiv}>
                                <p className={labelcss.labelsText}>Tags: </p>
                                    {labels.map((label) => (
                                        <div key={label.id} className={labelcss.whiteLabel}>
                                            {label.Tag}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </ContentWrapper>
                    </ContentWrapper>
                </Header>
                <ContentWrapper maxWidth="maxWidthSmall" paddedTop paddedBottom={true}>
                    <ContentWrapper isWide paddedBottom={true}>
                        <Heading isSemantic={true} variant="h2">
                            {__("Challenges")}
                        </Heading>
                        <RichContent>{ChallengeRichContent}</RichContent>
                    </ContentWrapper>
                    <ContentWrapper isWide paddedBottom={true}>
                        <Heading isSemantic={true} variant="h2">
                            {__("Solutions")}
                        </Heading>
                        <RichContent>{SolutionRichContent}</RichContent>
                    </ContentWrapper>
                    <ContentWrapper isWide paddedBottom={true}>
                        <Heading isSemantic={true} variant="h2">
                            {__("Results")}
                        </Heading>
                        <RichContent>{ResultRichContent}</RichContent>
                        {/* <Testimonial
                            title={testimonialtitle}
                            name={name}
                            testimonial={testimonial}
                            portrait={portrait}
                            occupation={occupation}
                        /> */}
                    </ContentWrapper>
                </ContentWrapper>
            </ContentWrapper>
        </Fragment>
    );
};

export default Page;

export const query = graphql`
    query($id: Int!, $hrefLang: String!) {
        pim {
            getProject(id: $id, defaultLanguage: $hrefLang) {
                Oplossing
                Resultaat
                Uitdaging
                testimonial
                testimonialtitle
                portrait {
                    id
                    fullpath
                }
                name
                occupation
                projecttitle
                projectpagetitle
                backgroundimage {
                  id
                  fullpath
                }
                projectintroduction
                seotitle
                seodescription
                stucturedData
                ogimage {
                    id
                    fullpath
                }
            }
        }
    }
`;
