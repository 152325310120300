export function generateThumbnailUrl(image, thumbnailName) {
    if (!image) {
        // SE placeholder image
        return "";
    }

    if (image.placeholder && !image.id && !image.fullpath) {
        return image.placeholder;
    }

    if (image.isLocalImage) {
        return image.fullpath;
    }

    const imageExtension = image.fullpath.substring(image.fullpath.lastIndexOf("."));
    const imageFolder = image.fullpath.substring(0, image.fullpath.lastIndexOf("/") + 1);
    const isSVG = imageExtension.includes("svg");
    const imageThumbnailPrefix = "image-thumb__";
    const imageId = image.id;
    const imageThumbnailDivider = "__";

    const generatedUrl = (isSVG)
        ? `${process.env.PIMCORE_BASE_URL}${image.fullpath}`
        : `${process.env.PIMCORE_BASE_URL}${imageFolder}${imageThumbnailPrefix}${imageId}${imageThumbnailDivider}${thumbnailName}`

    return generatedUrl;
}

