import Heading from "@components/common/Heading/Heading";
import RichContent from "@components/common/RichContent/RichContent";
import classNames from "classnames";
import React, { Component } from "react";
import css from "./Service.module.scss";



export class Service extends Component {
    render() {
        const { title, subtitle, richContent, position } = this.props;

        return (
            <div id={`service_${position}`} className={css.service}>
                <Heading
                    isSemantic={true}
                    variant="h2"
                    color="blue"
                    hasShadow={true}
                    className={classNames(css.heading)}
                >
                    {title}
                </Heading>
                <Heading
                    isSemantic={true}
                    variant="h3"
                    color="blue"
                    hasShadow={false}
                >
                    {subtitle}
                </Heading>
                <RichContent className={css.richContent}>
                    {richContent}
                </RichContent>
            </div>
        );
    }
}

export default Service;
