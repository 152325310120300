import React, { Fragment } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { __ } from "@i18n/util";
import css from "@styles-pages/contact.module.scss";
import { ServerSideMetaTags } from "../components/util/SOE/SOE";

import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import ContentWrapper from "../components/common/ContentWrapper/ContentWrapper";
import Header from "../components/common/Header/Header";
import Heading from "../components/common/Heading/Heading";
import ContactForm from "../components/contact/ContactForm/ContactForm";


/* eslint-disable no-undef */
export const metadata = {
    query: 'getContactConfig',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const VacancyLetterQuery = () => {
    const { pim } = useStaticQuery(graphql`
        {
        pim {
            getContactConfig(id: 413, defaultLanguage: "auto") {
            title
            subtitle
            seotitle
            seodescription
            stucturedData
            ogimage {
                id
                fullpath
            }
            }
        }
        }
    `);

    const {
        title,
        subtitle,
        stucturedData
    } = pim.getContactConfig;

    return (
        <Fragment>
            <ServerSideMetaTags pim={pim} metadata={metadata} structureddata={stucturedData}/>
            <ContentWrapper isWide={true}>
                <Header image={true} size="oneLiner">
                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                    >
                        <ContentWrapper
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >
                            <Heading isSemantic={true} variant="h1" color="white">
                                {title}
                            </Heading>
                            <HighlightedText color="white">
                                {subtitle}
                            </HighlightedText>
                        </ContentWrapper>
                    </ContentWrapper>
                </Header>
                <ContentWrapper paddedTop={true} paddedBottom={true}>

                    <ContactForm
                        variant="vacancyLetter"
                        endpoint={`${process.env.PIMCORE_BASE_URL}${process.env.PIMCORE_CONTACT_ENDPOINT}`}
                        errorMessage={__("Looks like something went wrong, please try again!")}
                        successMessage={__("We have received your request and we will keep you up to date.")}
                        resetMessage={__("Back to form")} />
                </ContentWrapper>
            </ContentWrapper>
        </Fragment>
    );
};

const Page = () => {
    return <VacancyLetterQuery />;
};

export default Page;
