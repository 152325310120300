export function generateLayout(layout) {
    let generatedLayout = {};

    Object.keys(layout).forEach((key) => {
        generatedLayout = {
            ...generatedLayout,
            [key]: layout[key] !== undefined ? key : "",
        };
    });

    return generatedLayout;
}