import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import css from "./ImageSlider.module.scss";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";

import classNames from "classnames";

export class ImageSlider extends React.Component {
    render() {

        const { imageObjects, imageTitles, width, children, greyScale } = this.props;

        const settings = (width === "fixed")
            ? {
                infinite: true,
                lazyLoad: false,
                slidesToShow: 5,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 3000,
                // speed: 6000,
                pauseOnHover: false,
                arrows: true,
                responsive: [

                    {
                        breakpoint: 1400,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1,
                            arrows: true
                        }
                    },
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 830,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 510,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false,
                        }
                    }
                ]
            }

            : {
                infinite: true,
                lazyLoad: false,
                slidesToShow: 1,
                autoplay: true,
                autoplaySpeed: 3000,
                centerMode: true,
                arrows: false,
                variableWidth: true,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 320,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerMode: false,
                            arrows: false,
                            variableWidth: false,
                            adaptiveHeight: false,
                        }
                    }
                ]
            }
        return (
            <div>
                <div className={classNames(css.container, css[width])}>
                    <Slider {...settings}>
                        {imageObjects.map((image) => {
                            return (
                                <div className={css.itemParent} key={image.id}>
                                    <div className={css.item}>
                                        {image.url && (<a href={image.url} target={image.newTab ? "_blank" : null}>
                                            <img
                                                loading="lazy"
                                                alt={image.text}
                                                //styling when prop greyScale has been added
                                                className={greyScale ? css.imagebw : css.image}
                                                src={generateThumbnailUrl(
                                                    image,
                                                    "carousel_thumbnail"
                                                )}
                                            />
                                        </a>)}
                                        {!image.url && (
                                            <img
                                                loading="lazy"
                                                alt={image.text}
                                                //styling when prop greyScale has been added
                                                className={greyScale ? css.imagebw : css.image}
                                                src={generateThumbnailUrl(
                                                    image,
                                                    "carousel_thumbnail"
                                                )}
                                            />
                                        )}

                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
                <div className={css.sliderButton}>
                    {children}
                </div>
            </div>

        );
    }
}

export default ImageSlider;
