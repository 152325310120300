import { initLanguages } from '@i18n/i18next';
import { graphql, useStaticQuery } from "gatsby";
import React, { Fragment } from 'react';

const LanguageProvider = ({ children }) => {
    const { pim } = useStaticQuery(graphql`
        query {
            pim {
                getDutchTranslations: getTranslationListing(defaultLanguage: "nl_BE") {
                    edges {
                        node {
                            KeyText
                            Translation
                        }
                    }
                }
                getEnglishTranslations: getTranslationListing(defaultLanguage: "en") {
                    edges {
                        node {
                            KeyText
                            Translation
                        }
                    }
                }
            }
        }
    `);

    const nl = {};
    const en = {};
    pim.getDutchTranslations.edges.forEach(({ node }) => { nl[node.KeyText] = node.Translation })
    pim.getEnglishTranslations.edges.forEach(({ node }) => { en[node.KeyText] = node.Translation })

    initLanguages({
        nl: nl,
        en: en
    });

    return <Fragment>{ children }</Fragment>
}

export default LanguageProvider;