import React from 'react'
import classNames from 'classnames'
import css from "@styles-pages/softwareBlock.module.scss";

import { generateThumbnailUrl } from "@util/thumbnail_url_generator";

export function ImageGrid(props) {

  const { imageObject } = props;

  if (imageObject[0] !== null) {

    return (
        <ul className={classNames(css.flexContainer)}>
          <li>
            <img className={css.logoBig} alt="image" loading="lazy" src={generateThumbnailUrl(imageObject[0], "customer_logo_thumbnail")} />
          </li>
        </ul>

    )

  } else {

    return (
      <>

        <ul className={classNames(css.flexContainer)}>


          {imageObject.filter(Boolean).map(im => (
            <li key={im.id}>
              <img className={css.logo} alt="logo" loading="lazy" src={generateThumbnailUrl(im, "studio_emma_badge_thumbnail")} />
            </li>
          )
          )}
        </ul>
      </>
    )


  }
}

export default ImageGrid;