/* eslint eqeqeq: 0 */

import React, { Fragment, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { __ } from "@i18n/util";
import urlBuilder from "../util/url-builder";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import { ServerSideMetaTags } from "../components/util/SOE/SOE";

import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import LinkButtonRow from "@components/common/LinkButtonRow/LinkButtonRow";
import LinkButton from "@components/common/LinkButton/LinkButton";
import Blog from "../components/common/Blog/Blog";
import BlogSkeleton from "../components/common/Blog/BlogSkeleton";
import BlogGrid from "../components/common/BlogGrid/BlogGrid";
import Fade from '@components/util/Fade';

import labelcss from "@styles/label.module.scss";
import css from "@components/common/Blog/Blog.module.scss"
import classNames from "classnames";

/* eslint-disable no-undef */
export const metadata = {
    query: 'getNewsConfigListing',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const NewsQuery = () => {
    const { pim } = useStaticQuery(graphql`
        query {
            pim {
                getNewsConfigListing(defaultLanguage: "auto") {
                    edges {
                        node {
                            pagetitle
                            pagesubtitle
                            blogtitle
                            othernewstitle
                            featuredposts {
                                ... on PIM_object_NewsPost {
                                    cardPreviewImage {
                                        id
                                        fullpath
                                    }
                                    Tags {
                                        ... on PIM_object_Tag {
                                            id
                                            Tag
                                        }
                                    }
                                    Title
                                    IntroText
                                    Author
                                    PublishDate
                                    id
                                }
                            }
                            seodescription
                            seotitle
                            stucturedData
                            ogimage {
                                id
                                fullpath
                            }
                        }
                    }
                }
                getNewsPostListing(
                    defaultLanguage: "auto"
                    sortBy: "PublishDate"
                    sortOrder: "DESC"
                ) {
                    edges {
                        node {
                            cardPreviewImage {
                                id
                                fullpath
                            }
                            Tags {
                                ... on PIM_object_Tag {
                                    id
                                    Tag
                                }
                            }
                            Title
                            IntroText
                            Author
                            PublishDate
                            id
                        }
                    }
                }
                    getTagListing(defaultLanguage: "auto", ids: "270,112,111") {
                    edges {
                        node {
                        Tag
                        }
                    }
                    }
            }
        }
    `);

    const { pagetitle, pagesubtitle, blogtitle, othernewstitle, stucturedData } = pim.getNewsConfigListing.edges[0].node;
    const filteringLabels = pim.getTagListing.edges
    const [selectedLabels, setSelectedLabels] = useState([])
    let anySelected = selectedLabels.length > 0
    const [isSkeleton, setIsSkeleton] = useState(false)

    const toggleSkeleton = () => {
        setIsSkeleton(true)
        setTimeout(() => { setIsSkeleton(false) }, 250)
    }

    const removeElementFromArray = (array, element) => {
        const result = array.filter(item => item !== element);
        setSelectedLabels(result)
    }

    const toggleLabel = (label, element) => {
        if (!selectedLabels.includes(label)) {
            setSelectedLabels([...selectedLabels, label])
            element.style.border = "1px solid #f27935"
        }
        else {
            removeElementFromArray(selectedLabels, label)
            element.style.border = "1px solid transparent"
        }
        toggleSkeleton()
    }

    let featuredPosts = [];

    if (pim.getNewsConfigListing.edges[0].node.featuredposts !== null) {

        featuredPosts = pim.getNewsConfigListing.edges[0].node.featuredposts.map(
            (node) => ({
                id: node.id,
                url: urlBuilder.buildDynamicUrl(node.id),
                image: generateThumbnailUrl(
                    node.cardPreviewImage,
                    "news_preview_thumbnail"
                ),
                text: node.IntroText?.replace(/^(.{200}[^\s]*).*/, "$1..."),
                labels: node.Tags != undefined ? node.Tags.map((t) => t.Tag) : [],
                title: node.Title,
                author: node.Author,
                date: node.PublishDate,
                featured: true,
            })
        );
    }

    const items = pim.getNewsPostListing.edges.map(({ node }) => ({
        url: urlBuilder.buildDynamicUrl(node.id),
        image: generateThumbnailUrl(
            node.cardPreviewImage,
            "news_preview_thumbnail"
        ),
        text: node.IntroText?.replace(/^(.{200}[^\s]*).*/, "$1..."),
        labels: node.Tags != undefined ? node.Tags.map((t) => t.Tag) : [],
        title: node.Title,
        author: node.Author,
        date: node.PublishDate,
        featured: featuredPosts.some(post => post.id === node.id),
    }));

    const filteredPosts = items.filter(post => post.labels.some((label) => selectedLabels.includes(label)));

    //featured items have a set order coming from the backoffice

    //non-featured items are just posted based on publishData
    const nonFeaturedPosts = items.filter((item) => !item.featured);

    return (
        <Fragment>
            <ServerSideMetaTags pim={pim} metadata={metadata} structureddata={stucturedData} />
            <ContentWrapper isWide={true}>
                <Header image={true} size="oneLiner">
                    <ContentWrapper
                        isWide={false}
                        vertical="center"
                    >
                        <ContentWrapper
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >
                            <Heading
                                isSemantic={true}
                                variant="h1"
                                color="white"
                            >
                                {pagetitle}
                            </Heading>
                            <HighlightedText color="white">
                                {pagesubtitle}
                            </HighlightedText>
                            <LinkButtonRow>
                                <LinkButton
                                    link={urlBuilder.buildStaticUrl('contact')}
                                    variant="orangeRevert"
                                    text={__("Contact")}
                                />
                                <LinkButton
                                    link={urlBuilder.buildStaticUrl('webinars')}
                                    variant="orangeRevert"
                                    text={__("follow a webinar")}
                                />
                            </LinkButtonRow>
                        </ContentWrapper>
                    </ContentWrapper>
                </Header>
            </ContentWrapper>
            {featuredPosts.length &&
                <Fade duration={2000}>
                    <ContentWrapper isWide={true} bgColor={!anySelected && "bgColorLighterGrey"}>
                        {/* {filteringLabels && filteringLabels.length > 0 && (
                 <div className={css.tagDiv}>
                     <p className={css.tagText}>{__("Sort on tag:")}</p>
                     {filteringLabels.map((label) => (
                         <div onClick={(e) => { toggleLabel(label.node.Tag, e.target) }} key={label.node.id} className={classNames(labelcss.label,css.labelCursor)}>
                             {label.node.Tag}
                         </div>
                     ))}
                 </div>
             )}{!anySelected && */}
                        <ContentWrapper paddedTop paddedBottom>
                            <Heading variant="h2" className="u-centered">
                                {blogtitle}
                            </Heading>
                            <BlogGrid filter={3}>
                                {featuredPosts.map((item, index) => (
                                    <Blog variant="featured" key={index} {...item} />
                                ))}
                            </BlogGrid>
                        </ContentWrapper>
                    </ContentWrapper>
                </Fade>
            }

            <Fade duration={2000}>
                <ContentWrapper isWide={true}>
                    <ContentWrapper paddedTop paddedBottomLarge>
                        {!anySelected &&
                            <Heading variant="h2" className="u-centered">
                                {othernewstitle}
                            </Heading>}
                        <BlogGrid filter={!anySelected ? 4 : 6}>
                            {!anySelected
                                ? nonFeaturedPosts.map((item, index) => (
                                    <Blog key={index} {...item} />
                                ))
                                : filteredPosts.map((item, index) => (
                                    isSkeleton
                                        ? <BlogSkeleton key={index} />
                                        : <Blog key={index} {...item} />
                                ))}
                        </BlogGrid>
                    </ContentWrapper>
                </ContentWrapper></Fade>
        </Fragment>
    );
};

const Page = () => {
    return <NewsQuery />;
};

export default Page;
