import React from 'react'
import classNames from 'classnames';
import css from './FloatingIcons.module.scss';


function FloatingIcons(props) {
    if (props.active) {
        return (
            <>
                <div className={css.floatContainer}>
                    <div className={classNames(css.float, css.avatar, css.pimcore, css.float1)}>
                        <img src="https://avatars.githubusercontent.com/u/686745?s=200&v=4" alt="" />
                    </div>
                    <div className={classNames(css.float, css.avatar, css.float2)}>
                        <img src="http://pimcore.docker/_default_upload_bucket/magento-seeklogo.svg" alt="" />
                    </div>
                    <div className={classNames(css.float, css.avatar, css.float3)}>
                        <img src="http://pimcore.docker/_default_upload_bucket/cloudstar.svg" alt="" />
                    </div>
                    <div className={classNames(css.float, css.avatar, css.float4)}>
                        <img src="http://pimcore.docker/_default_upload_bucket/adobe-commerce_2.svg" alt="" />
                    </div>
                    <div className={classNames(css.float, css.avatar, css.float5)}>
                        <img src="http://pimcore.docker/_default_upload_bucket/aws.svg" alt="" />
                    </div>
                    <div className={classNames(css.float, css.avatar, css.float6)}>
                        <img src="http://pimcore.docker/_default_upload_bucket/react.svg" alt="" />
                    </div>
                </div>
            </>
        )
    } else {
        return null;
    }

}

export default FloatingIcons