import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

function Map(props) {
    const { style, location } = props;

    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        // const bounds = new window.google.maps.LatLngBounds();
        // map.fitBounds(bounds);
        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    //options reference: https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions

    return (
        <LoadScript googleMapsApiKey={process.env.GOOGLE_API_KEY}>
            <GoogleMap
                mapContainerStyle={style}
                center={location}
                zoom={15}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                <Marker position={location} />
            </GoogleMap>
        </LoadScript>
    );
}

export default React.memo(Map);
