
import React from 'react';

// Icon Toggle
export const Toggle = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M0 358.4h324.3v34.1H0z" fill="currentColor" />
        <path d="M0 238.9h512V273H0z" fill="currentColor" />
        <path d="M0 119.5h512v34.1H0z" fill="currentColor" />
    </svg>

// Testing rebrand icon as hamburger menu icon!
/* <svg viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M0 32.9999H44.9999V44.9999H0V32.9999Z" fill="white" class="line-bottom"/>
<path opacity="0.75" fill-rule="evenodd" clip-rule="evenodd" d="M0 16.5H44.9999V28.4999H0V16.5Z" fill="white" class="line-middle"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.6667 3.59999L45 0V12H14.6667V3.59999Z" fill="white" class="line-top"/>
</svg> */



);

// Icon Close
export const Close = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path transform="rotate(-45.001 255.998 256.004)" d="M233.7-77.9h44.5v667.8h-44.5z" fill="currentColor" />
        <path transform="rotate(-45.001 255.998 256.004)" d="M-77.9 233.7h667.8v44.5H-77.9z" fill="currentColor" />
    </svg>
);

// Config menu css class OPEN
export const MENU_OPEN = 'isOpen';

// Config menu css class CLOSED
export const MENU_CLOSED = 'isClosed';
