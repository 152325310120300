/* eslint eqeqeq: 0 */

import React, { Fragment } from "react";
import { graphql } from "gatsby";
import { __ } from "@i18n/util";

import { ServerSideMetaTags } from "../components/util/SOE/SOE";
import Header from "@components/common/Header/Header";
import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import LinkButton from "@components/common/LinkButton/LinkButton";
import Fade from '@components/util/Fade';

import labelcss from "@styles/label.module.scss";
import css from "@components/common/Partners/Partner-details.module.scss"
import classNames from "classnames";

import RichContent from "@components/common/RichContent/RichContent";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import { getFirstItemFromQuery } from "@util/mapper";
import urlBuilder from "@util/url-builder";

/* eslint-disable no-undef */
export const metadata = {
    query: 'getTechnologyLink',
    title: 'seotitle',
    description: 'seodescription',
    image: 'ogimage'
};

const PartnerDetail = (props) => {

    const { data } = props;

    const partnerData = data.pim.getTechnologyLink;

    const { allPartnersButtonText, partnerWebsiteLinkText } = getFirstItemFromQuery(data.pim.getPartnersConfigListing);

    return (
        <Fragment>
            <ServerSideMetaTags pim={data.pim} metadata={metadata} />
            <ContentWrapper isWide={true}>
                <Header image={true} size="smallHeader">
                    <ContentWrapper
                        isWide={false}
                        vertical="center"
                    >
                    </ContentWrapper>
                </Header>
            </ContentWrapper>

            <Fade duration={2000}>
                <ContentWrapper maxWidth="maxWidthLarge" paddedBottom={true} >

                    <div className={classNames(css.container)}>

                        {partnerData.techLogo &&
                            <div className={classNames(css.logoWrapper)}>

                                <img src={generateThumbnailUrl(
                                    partnerData.techLogo,
                                    "carousel_thumbnail"
                                )} alt={`Logo van ${partnerData.techName}`} />
                            </div>
                        }

                        <div className={classNames(css.content, {
                            [css.noLogoStyling]: !partnerData.techLogo
                        })}>

                            {partnerData.partnerTier && <div className={classNames(css.labelWrapper)}>
                                <div className={classNames(labelcss.label, labelcss.petrol)}>
                                    {partnerData.partnerTier}
                                </div>
                            </div>}
                            {partnerData.partnerIntro &&

                                <div className={classNames(css.text)}>
                                    {partnerData.partnerIntro}
                                </div>

                            }
                            {partnerData.techURL && (
                                <div className={classNames(css.link)}>
                                    <a href={partnerData.techURL} title={partnerData.techURL} rel="noreferrer" target="_blank">{__("Website")}</a>
                                </div>
                            )}
                        </div>
                    </div>

                </ContentWrapper>

            </Fade>
            {partnerData.partnerContent && (
                <Fade duration={2000}>
                    <ContentWrapper maxWidth="maxWidthSmall">
                        <div className={classNames(css.bottomWrapper)}>
                            <div className={classNames(css.bottomText)}>
                                <RichContent>
                                    {partnerData.partnerContent}
                                </RichContent>
                            </div>
                        </div>
                    </ContentWrapper>
                </Fade>
            )}

            <Fade duration={2000}>
                <ContentWrapper maxWidth="maxWidthSmall">
                    <div className={classNames(css.buttonWrapper)}>
                        <LinkButton
                            text={__("All partners")}
                            link={urlBuilder.buildStaticUrl("/partners")}
                        />
                    </div>
                </ContentWrapper>
            </Fade>

        </Fragment>
    );
};

export default PartnerDetail;

export const query = graphql`
    query($id: Int!, $hrefLang: String!) {
        pim {
            getTechnologyLink(id: $id, defaultLanguage: $hrefLang) {
                partnerContent
                partnerIntro
                partnerShortDescription
                partnerTier
                techLogo {
                    id
                    fullpath
                }
                techURL
                techName
                techNewtab
                ogimage{
                    id
                    fullpath
                }
                seotitle
            }
            getPartnersConfigListing(defaultLanguage: "auto", filter: "{\"UniqueRef\": \"partnersConfig\"}")  {
                edges{
                    node{
                        allPartnersButtonText
                        partnerWebsiteLinkText
                    }
                }
            }
        }
    }
`;
