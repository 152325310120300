import { graphql, useStaticQuery } from "gatsby";
import { LocalizedLink as Link } from "gatsby-theme-i18n";

import React from "react";
import Dropdown from "../../../../common/Dropdown/Dropdown";
import css from "./Navigation.module.scss";

import { setIsMobileNavigationOpen as setIsMobileNavigationOpenAction } from "@redux/actions";
import { setIsStickyNavigationOpen as setIsStickyNavigationOpenAction } from "@redux/actions";
import { connect } from "react-redux";

import ContactButtons from "../ContactButtons/ContactButtons";

import classNames from "classnames";

const NavigationQuery = (props) => {
    const { pim } = useStaticQuery(graphql`
        query {
            pim {
                getHeaderListing(defaultLanguage: "auto") {
                    edges {
                        node {
                            NavContent {
                                ... on PIM_object_Link {
                                    id
                                    Link
                                    Name
                                    DropDownLinks {
                                        ... on PIM_object_Link {
                                            Link
                                            Name
                                        }
                                        }
                                    PageConfig {
                                        ... on PIM_object_ServicesConfig {
                                            classname
                                            title
                                            OtherservicesURL
                                            OtherServicesNavTitle
                                            id
                                            featuredservices {
                                                ... on PIM_object_FeaturedService {
                                                    URL
                                                    id
                                                    title
                                                    listingTitle
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const navItems = pim.getHeaderListing.edges[0].node.NavContent;

    return (
        <nav>
            <ul>
                <li className={`${css.link} ${css.big} ${css.mobile}`}>
                    <Link
                        onClick={() => {
                            props.setIsMobileNavigationOpen(false);
                        }}
                        to="/"
                    >
                        <span>Home</span>
                    </Link>
                </li>
                {navItems.map((item) => (
                    <li key={item.id} className={`${css.link} ${css.big}`}>
                        {item.DropDownLinks === null ? (
                            <Link
                                onClick={() => {
                                    props.setIsMobileNavigationOpen(false);
                                }}
                                to={`${item.Link}`}
                                activeClassName="active"
                            >
                                <span>{item.Name}</span>
                            </Link>
                        ) : (
                            <>
                                <ul className={css.mobile}>
                                    <li className={`${css.big}`}>
                                        <Link
                                            onClick={() => {
                                                props.setIsMobileNavigationOpen(
                                                    false
                                                );
                                            }}
                                            to={`${item.Link}`}
                                        >
                                            <span>{item.Name}</span>
                                        </Link>
                                    </li>
                                    {item.DropDownLinks.map((dropdownItem) => (
                                        <li
                                            key={dropdownItem.id}
                                            className={`${css.dropdownLink}`}
                                        >
                                            <Link
                                                onClick={() => {
                                                    props.setIsMobileNavigationOpen(
                                                        false
                                                    );
                                                }}
                                                to={`${dropdownItem.Link}`}
                                            >
                                                <span>
                                                    {dropdownItem.Name}
                                                </span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                                <div className={css.desktop}>
                                    <Dropdown
                                        dropdownInfo={item.DropDownLinks}
                                        headerTitle={item.Name}
                                        headerUrl={item.Link}
                                    ></Dropdown>
                                </div>
                            </>
                        )}
                    </li>
                ))}
                <li className={classNames(css.mobile, css.big)}>
                    <ContactButtons />
                </li>
            </ul>
        </nav>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setIsMobileNavigationOpen: (boolean) =>
        dispatch(setIsMobileNavigationOpenAction(boolean)),
    setIsStickyNavigationOpen: (boolean) =>
        dispatch(setIsStickyNavigationOpenAction(boolean)),
});

const mapStateToProps = (globalState) => ({
    isMobileNavigationOpen: globalState.isMobileNavigationOpen,
    isStickyNavigationOpen: globalState.isStickyNavigationOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationQuery);
