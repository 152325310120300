import React from 'react'
import css from "./DownloadCta.module.scss";
import LinkButton from '../common/LinkButton/LinkButton';
import urlBuilder from '../../util/url-builder';

const DownloadCta = (downloadData) => {

    // downloadData prop get spread individualy using the object spread operator {...obj}

    return (
        <div className={css.container}>
            <div className={css.content}>
                <div className={css.title} dangerouslySetInnerHTML={{ __html: downloadData.text }}>
                </div>
                <div className={css.btnWrapper}>
                    <LinkButton
                        variant='white'
                        text={downloadData.buttonTxt}
                        link={downloadData.buttonUrl}
                        uppercase={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default DownloadCta