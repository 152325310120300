// https://github.com/gatsbyjs/gatsby/tree/master/examples/using-redux
import React from 'react';
import { Provider } from 'react-redux';
import { CookieDataProvider } from '../hooks/useCookieData';
import createStore from './store';

export const store = createStore();

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts

    return (
        <CookieDataProvider>
        <Provider store={store}>{element}</Provider>
        </CookieDataProvider>
    );
};