import React from "react";
import { LocalizedLink as Link } from "gatsby-theme-i18n";

import urlBuilder from '../../../util/url-builder';
import Heading from "@components/common/Heading/Heading";
import css from './VacancyList.module.scss';
import { __ } from "@i18n/util";


export class VacancyList extends React.Component {
    render() {
        const { vacancies, anchor } = this.props;

        return (
            <div id={anchor}>
                <Heading isSemantic={true} variant="h2" className={css.title}>{ __("Onze vacatures") }</Heading>
                {vacancies ?
                    <ul className={css.list}>
                        {vacancies?.map(({ id, functiontitle }) => {

                            return (
                                <li key={id} className={css.listItem}>
                                    <Link
                                        className={css.link}
                                        to={urlBuilder.buildDynamicUrl(id)}
                                    >
                                        <span>{functiontitle}</span>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                    : <div className={css.block}>
                        <div class="alert alert-warning">
                            <strong>{__("There are currently no open vacancies.")}</strong>
                            <br />{__("Check back on this page later to make sure you don't miss any.")}
                        </div>
                    </div>
                }

            </div>
        );
    }
}

export default VacancyList;
