import React, { Component } from 'react';
import classNames from 'classnames';
import colors from '@styles/colors.module.scss';
import { SUPPORTED_HEADINGS } from './Heading.config';
import css from './Heading.module.scss';


export class ShadowHeading extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        const {
            children, color, className
        } = this.props;
        return (
            <div className={classNames(
                css.shadowHeading,
                colors[color],
                className
            )}
            >
                { children }
            </div>
        );
    }
}

export class Heading extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    isSupportedVariant = (variant) => {
        if (SUPPORTED_HEADINGS.includes(variant)) {
            return variant;
        }

        return false;
    }

    render() {
        const {
            children, isSemantic, hasShadow, variant, color, family, weight, dataAttributes, className
        } = this.props;
        const HeadingVariant = this.isSupportedVariant(variant);

        let attributes = {};
        if (dataAttributes) {
            Object
                .keys(dataAttributes)
                .forEach((key) => { attributes = { ...attributes, [`data-${key}`]: dataAttributes[key] }; });
        }

        if (HeadingVariant && isSemantic) {
            return (
                <HeadingVariant
                    {... attributes}
                    className={classNames(
                        colors[color],
                        css[family],
                        css[weight],
                        className
                    )}
                >
                    { children }
                    {
                        hasShadow
                        && (
                            <ShadowHeading>
                                { children }
                            </ShadowHeading>
                        )
                    }

                </HeadingVariant>
            );
        }

        return (
            <div
                {... attributes}
                className={classNames(
                    css[variant],
                    colors[color],
                    css[family],
                    css[weight],
                    className
                )}
            >
                { children }
                {
                    hasShadow
                    && (
                        <ShadowHeading>
                            { children }
                        </ShadowHeading>
                    )
                }
            </div>
        );
    }
}

export default Heading;
