import React, { Component, Fragment } from "react";
import Heading from "@components/common/Heading/Heading";
import css from "./InformationGrid.module.scss";

export class InformationGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { title, items, anchor } = this.props;

        return (
            <div id={anchor}>
                <Heading
                    isSemantic={true}
                    variant="h2"
                    className={css.containerTitle}
                >
                    {title}
                </Heading>
                <div className={css.container}>
                    {items.map((item, index) => {
                        const position = index + 1;
                        return (
                            <div
                                key={index}
                                className={css.item}
                                data-index={position}
                            >
                                <div className={css.title}>{item.title}</div>
                                <div className={css.text}>{item.text}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default InformationGrid;
