import React, { Component } from "react";
import css from "./BlogSkeleton.module.scss";

export class BlogSkeleton extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className={css.mainDiv}>
                <div className={css.imageDiv}></div>
                <div className={css.labelDiv}>
                    <div className={css.label}></div>
                    <div className={css.label}></div>
                </div>
                <div className={css.textDiv}>
                    <div className={css.title}></div>
                    <div className={css.text}></div>
                </div>
            </div>
        );
    }
}

export default BlogSkeleton;
