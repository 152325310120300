import React from 'react'
import { graphql, useStaticQuery} from "gatsby";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import classNames from "classnames";
import urlBuilder from "@util/url-builder";
import Heading from '@components/common/Heading/Heading';
import LinkButton from "@components/common/LinkButton/LinkButton";

import css from './ServicesGrid.module.scss';
import cssHeading from '@components/common/Heading/Heading.module.scss';

function ServicesGrid() {
    const { pim } = useStaticQuery(graphql`
        {
            pim {
                getHomeConfig(id: 157, defaultLanguage: "auto") {
                    Onsprocespicture{
                        id
                        fullpath
                    }
                    onsprocessectiontitle
                    onsprocessectiontext
                    onsprocesButtontitle
                    onsprocesURL
                    onsproces {
                        ... on PIM_object_Page {
                            id
                            pageIntroTitle
                            pageIntroSubtitle
                        }
                    }
                }
            }
        }
    `);

    const {onsprocessectiontitle, onsprocessectiontext, onsprocesButtontitle, onsprocesURL, Onsprocespicture} = pim.getHomeConfig;
    const OnsprocespictureUrlPath = generateThumbnailUrl(Onsprocespicture, 'general_thumbnail');
    return (
        <div className={css.servicesGrid}>
            <div className={css.servicesGridSection}>
                <div className={css.servicesGridSectionContent}>
                    <Heading
                        variant="h2"
                        isSemantic={true}>
                            {onsprocessectiontitle}
                    </Heading>

                    <p>{onsprocessectiontext}</p>
                </div>
                {/* <div className={css.servicesGridSectionImage}>
                    <img alt={onsprocessectiontitle} loading="lazy" src={OnsprocespictureUrlPath} />
                </div> */}
            </div>

            <div className={css.servicesGridItems}>
                {
                    pim.getHomeConfig.onsproces.map(({ id, pageIntroTitle, pageIntroSubtitle }) =>
                        (
                            <div className={css.servicesGridItem} key={id}>
                                <div className={classNames(css.servicesGridItemTitle, cssHeading.h2)}
                                    data-title={pageIntroTitle}>
                                        {pageIntroTitle}
                                </div>

                                <p className={css.servicesGridItemSubtitle}>{pageIntroSubtitle}</p>
                            </div>
                        )
                    )
                }
            </div>
            <div className={css.servicesGridButton}>
                <LinkButton
                    link={urlBuilder.buildStaticUrl(onsprocesURL)}
                    variant="blue"
                    text={onsprocesButtontitle}
                    uppercase={false}
                />
            </div>
        </div>
    )
}

export default ServicesGrid