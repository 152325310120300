import css from "./Modal.module.scss"
import React from "react";
import ReactDOM from "react-dom"

const Modal = ({ handleClose, show, children }) => {
    return ReactDOM.createPortal(
        <div className={show
            ? `${css.modalDisplayBlock}`
            : `${css.modalDisplayNone}`}>
            <section className={`${css.modalMain}`}>
                {children}
            </section>
        </div>
    ,document.body);
};

export default Modal;
