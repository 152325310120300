import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import React from "react";
import css from "./PartnerGrid.module.scss";
import classNames from "classnames";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import urlBuilder from "@util/url-builder";
import { PartnerLink } from "./PartnerSliderItem";

const PartnerGrid = (props) => {
    const { partners } = props;

    return (
        <div className={classNames(css.partnerContainer)}>
            {
                partners.map((partner, index) => {
                    return (
                        <PartnerLink
                            className={classNames(css.partnerItem)}
                            key={partner.id}
                            id={partner.id}
                            partnerPageUrl={partner.partnerPageUrl}
                            techLogo={partner.techLogo?.fullpath}
                            techNewtab={partner.techNewtab}
                            text={partner.techName}
                            >
                            {partner.partnerTier &&
                                <div className={classNames(css.partnerLabel)}>{partner.partnerTier}</div>
                            }

                            {partner.techLogo &&
                                <div className={classNames(css.partnerpartnerLogo)}>

                                    <img className={classNames(css.partnerLogo)}
                                        src={generateThumbnailUrl(
                                            partner.techLogo,
                                            "logo_thumbnail"
                                        )}
                                        alt={`${partner.techName} logo`} />
                                </div>
                            }

                            {partner.partnerShortDescription &&
                                <div className={classNames(css.partnerpartnerText,
                                    { [css.noLogoStyling]: !partner.techLogo },
                                )}>
                                    {partner.partnerShortDescription}
                                </div>
                            }
                        </PartnerLink>
                    )
                })
            }
        </div>
    );

}

export default PartnerGrid;
