import { createStore as reduxCreateStore } from 'redux';

const reducer = (state, action) => {
    if (action.type === 'SET_CURRENT_SERVICE') {
        return {
            ...state,
            currentService: action.currentService
        };
    }

    if (action.type === 'SET_SERVICE_SCROLL_DIRECTION') {
        return {
            ...state,
            serviceScrollDirection: action.direction
        };
    }

    if (action.type === 'SET_META') {
        return {
            ...state,
            meta: action.meta
        };
    }

    if (action.type === 'SET_PAGES') {
        return {
            ...state,
            pages: action.pages
        };
    }

    if (action.type === 'SET_LOCALE') {
        return {
            ...state,
            locale: action.locale
        };
    }

    if (action.type === 'SET_IS_MOBILE_NAVIGATION_OPEN') {
        return {
            ...state,
            isMobileNavigationOpen: action.setIsMobileNavigationOpen
        };
    }

    if (action.type === 'SET_IS_STICKY_NAVIGATION_OPEN') {
        return {
            ...state,
            isStickyNavigationOpen: action.setIsStickyNavigationOpen
        };
    }

    return state;
};

const initialState = {
    currentService: 0,
    isMobileNavigationOpen: false,
    isStickyNavigationOpen: false,
    serviceScrollDirection: 'FORWARD',
    locale: 'nl_BE',
    pages: {},
    meta: {
        title: '',
        description: '',
        image: ''
    }
};


const createStore = (typeof window !== 'undefined')
    ? () => reduxCreateStore(reducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
    : () => reduxCreateStore(reducer, initialState);

export default createStore;
