import Card from "@components/common/Card/Card";
import CardGrid from "@components/common/CardGrid/CardGrid";
import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import CustomerGrid from "@components/references/CustomerGrid/CustomerGrid";
import LinkButton from "@components/common/LinkButton/LinkButton";
import LinkButtonRow from "@components/common/LinkButtonRow/LinkButtonRow";

import css from "@styles-pages/portfolio.module.scss";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import urlBuilder from "@util/url-builder";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { checkButton } from '@util/check-button';
import { ServerSideMetaTags } from "../components/util/SOE/SOE";
import Fade from '@components/util/Fade';


/* eslint-disable no-undef */
export const metadata = {
    query: 'getReferencesConfigListing',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const ProjectSection = ({ title, subtitle, projects }) => (
    <ContentWrapper paddedBottom>
        <Heading isSemantic={true} variant="h2">
            {title}
        </Heading>
        <p className={css.subtitle}>{subtitle}</p>
        <CardGrid filter={3}>
            {projects.map((project) => (
                <Card
                    key={project.id}
                    title={project.projecttitle}
                    subtitle={project.projectsubtitle}
                    url={urlBuilder.buildDynamicUrl(
                        project.id
                    )}
                    image={generateThumbnailUrl(
                        project.previewimage,
                        "case_preview"
                    )}
                />
            ))}
        </CardGrid>
    </ContentWrapper>
);

const TestimonialSection = ({ title, subtitle, testimonials }) => (
    <ContentWrapper paddedBottom>
        <Heading isSemantic={true} variant="h2">
            {title}
        </Heading>

        <p className={css.subtitle}>{subtitle}</p>

        <CardGrid filter={3}>
            {testimonials.map((testimonial) => (
                <Card
                    key={testimonial.id}
                    title={testimonial.title}
                    subtitle={testimonial.subtitle}
                    url={urlBuilder.buildDynamicUrl(
                        testimonial.id
                    )}
                    image={generateThumbnailUrl(
                        testimonial.previewimage,
                        "card_preview_thumbnail"
                    )}
                />
            ))}
        </CardGrid>
    </ContentWrapper>
);

const CustomersSection = ({ title, customers }) => (
    <ContentWrapper>
        <CustomerGrid title={title} customers={customers} />
    </ContentWrapper>
);

const ReferenceQuery = () => {
    const { pim } = useStaticQuery(graphql`
        query {
            pim {
                getReferencesConfigListing(defaultLanguage: "auto") {
                    edges {
                        node {
                            seotitle
                            seodescription
                            ogimage {
                                id
                                fullpath
                            }
                            button1URL
                            button1txt
                            button2URL
                            button2txt

                            pagesubtitle
                            pagetitle
                            projectssubtitle
                            projectstitle
                            projects {
                                ... on PIM_object_Project {
                                    id
                                    previewimage {
                                        id
                                        fullpath
                                    }
                                    projecttitle
                                    projectsubtitle
                                }
                                ... on PIM_object_Projectnew {
                                  id
                                  previewimage {
                                    id
                                    fullpath
                                  }
                                  projecttitle
                                  projectsubtitle
                                }
                            }
                            testimonialstitle
                            testimonialssubtitle
                            testimonials {
                                ... on PIM_object_Testimonial {
                                    id
                                    title
                                    subtitle
                                    text
                                    previewimage {
                                        id
                                        fullpath
                                    }
                                }
                            }
                            customerstitle
                            customers {
                                ... on PIM_object_CustomerLink {
                                    id
                                    URL
                                    customer
                                    logo {
                                        id
                                        fullpath
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const data = pim.getReferencesConfigListing.edges[0].node;
    const { stucturedData } = data;

    const isBtn1 = checkButton(data.button1txt, data.button1URL);
    const isBtn2 = checkButton(data.button2txt, data.button2URL);

    return (
        <ContentWrapper isWide={true}>
            <ServerSideMetaTags pim={pim} metadata={metadata} structureddata={stucturedData} />
            <Header image={true} size="oneLiner">
                <ContentWrapper
                    isWide={false}
                    className={css.contentWrapper}
                >
                    <ContentWrapper
                        isWide={true}
                        maxWidth="maxWidthSmall"
                    >
                        <Heading isSemantic={true} variant="h1" color="white">
                            {data.pagetitle}
                        </Heading>
                        <HighlightedText color="white">
                            {data.pagesubtitle}
                        </HighlightedText>

                        {(isBtn1 || isBtn2) && (
                            <LinkButtonRow>
                                {isBtn1 && (
                                    <LinkButton
                                        link={urlBuilder.buildStaticUrl(data.button1URL)}
                                        variant="orangeRevert"
                                        text={data.button1txt}
                                    />
                                )}

                                {isBtn2 && (
                                    <LinkButton
                                        link={urlBuilder.buildStaticUrl(data.button2URL)}
                                        variant="orangeRevert"
                                        text={data.button2txt}
                                    />
                                )}
                            </LinkButtonRow>
                        )}
                    </ContentWrapper>
                </ContentWrapper>
            </Header>
            <ContentWrapper isWide={true} paddedTop={true} paddedBottom={true}>
                <Fade>
                    <TestimonialSection
                        title={data.testimonialstitle}
                        subtitle={data.testimonialssubtitle}
                        testimonials={data.testimonials}
                    /></Fade>
                <Fade duration={2000}>
                    <ProjectSection
                        title={data.projectstitle}
                        subtitle={data.projectssubtitle}
                        projects={data.projects}
                    /></Fade>
                <Fade duration={2000}>
                    <CustomersSection
                        title={data.customerstitle}
                        customers={data.customers}
                    /></Fade>
            </ContentWrapper>
        </ContentWrapper>
    );
};

const Page = () => {
    return <ReferenceQuery />;
};

export default Page;
