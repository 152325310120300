import React, { Component } from "react";
import classNames from "classnames";

import css from "./Column.module.scss";

export class Column extends Component {
    render() {
        const {
            innerRef,
            children,
            width,
            widthsm,
            widthmd,
            widthlg,
            widthxl,
            widthxxl,
            bgColor,
            className,
        } = this.props;

        return (
            <div
                ref={innerRef}
                className={classNames(
                    css.column,
                    css[width],
                    css[widthsm],
                    css[widthmd],
                    css[widthlg],
                    css[widthxl],
                    css[widthxxl],
                    css[bgColor],
                    className
                )}
            >
                {children}
            </div>
        );
    }
}

export const ForwardedColumn = React.forwardRef((props, ref) => (
    <Column {...props} innerRef={ref} />
));

export default Column;
