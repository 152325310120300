import React, { Component } from 'react'

import css from './InfoBox.module.scss';

export class InfoBoxGrid extends Component {
    render() {
        const { children } = this.props
        return (
            <div className={css.infoBoxGrid}>
                {children}
            </div>
        )
    }
}

export default InfoBoxGrid