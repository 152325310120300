import React, { Component } from 'react';
import colors from '@styles/colors.module.scss';
import classNames from 'classnames';
import css from './HighlightedText.module.scss';
export class HighlightedText extends Component {
    render() {
        const { color, children, borderColor } = this.props;
        return (
            <div className={classNames(css.highlightedText, css[borderColor])}>
                <p className={classNames(css.content, colors[color])}>
                    { children }
                </p>
            </div>
        );
    }
}

export default HighlightedText;
