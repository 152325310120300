import React, { Component } from "react";
import css from "./VideoPlayer.module.scss";


export class VideoPlayer extends Component {
    render() {
        return (
            <div className={css.container}>
                <iframe
                    title="Studio Emma video"
                    src={`https://player.vimeo.com/video/${this.props.videoLink}`}
                    frameBorder="0"
                    allow="fullscreen"
                    allowFullScreen
                ></iframe>
            </div>
        );
    }
}

export default VideoPlayer;
