import Heading from "@components/common/Heading/Heading";
import LinkButton from "@components/common/LinkButton/LinkButton";
import { __ } from "@i18n/util";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import React from "react";
import css from "./FeaturedService.module.scss";


export class FeaturedService extends React.Component {
    render() {
        const { logo, title, listingtext, url } = this.props;

        return (
            <div className={css.card}>
                <img
                    className={css.logo}
                    alt={title}
                    loading="lazy"
                    src={generateThumbnailUrl(logo, "logo_thumbnail")}
                />
                <Heading isSemantic={true} variant="h2" className={css.title}>
                    {title}
                </Heading>
                <p className={css.text}>{listingtext}</p>
                <LinkButton
                    variant="blue"
                    text={__("More info")}
                    link={url}
                />
            </div>
        );
    }
}

export default FeaturedService;
