import React, { Component } from 'react'

import css from './Usp.module.scss';

export class UspGrid extends Component {
    render() {
        const { children } = this.props
        return (
            <div className={css.uspGrid}>
                {children}
            </div>
        )
    }
}

export default UspGrid