import Heading from "@components/common/Heading/Heading";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import React from "react";
import css from "./CustomerGrid.module.scss";

export class CustomerGrid extends React.Component {
    render() {
        const { customers, title } = this.props;

        return (
            <div>
                <Heading isSemantic={true} variant="h2" className={css.title}>
                    {title}
                </Heading>
                <div className={css.container}>
                    {customers.map((customer) => {
                        const { logo, URL, id, customer:name } = customer;

                        return (
                            <div key={id} className={css.customer}>
                                <a className={css.link} href={URL} target="_blank" rel="noopener noreferrer">
                                    <img
                                        alt={name}
                                        loading="lazy"
                                        src={generateThumbnailUrl(
                                            logo,
                                            "customer_logo_thumbnail"
                                        )}
                                    />
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default CustomerGrid;
