import React, { Component } from "react";
import { generateLayout } from "@util/generate-layout";
import classNames from "classnames";

import {
    DEFAULT_TYPE_CLASS,
    WIDE_TYPE_CLASS,
    MOBILE_WIDE_TYPE_CLASS,
    DESKTOP_WIDE_TYPE_CLASS,
} from "./ContentWrapper.config";

import css from "./ContentWrapper.module.scss";

export class ContentWrapper extends Component {
    render() {
        const {
            innerRef,
            isWide,
            isMobileWide,
            isDesktopWide,
            horizontal,
            vertical,
            paddedTop,
            paddedTopLarge,
            paddedBottom,
            paddedBottomLarge,
            paddedOverlap,
            paddedNoSides,
            marginBottomNegative,
            textCenter,
            slider,
            maxWidth,
            bgColor,
            children,
            className,
            id,
        } = this.props;

        let type = isWide ? WIDE_TYPE_CLASS : DEFAULT_TYPE_CLASS;
        type = isMobileWide ? MOBILE_WIDE_TYPE_CLASS : type;
        type = isDesktopWide ? DESKTOP_WIDE_TYPE_CLASS : type;

        const layout = generateLayout({
            horizontal,
            vertical,
            paddedTop,
            paddedTopLarge,
            paddedBottom,
            paddedBottomLarge,
            paddedOverlap,
            paddedNoSides,
            marginBottomNegative,
            textCenter,
            slider
        });

        return (
            <div
                id={id}
                ref={innerRef}
                className={classNames(
                    css.contentWrapper,
                    css[type],
                    css[bgColor],
                    css[layout.horizontal],
                    css[layout.vertical],
                    css[layout.paddedTop],
                    css[layout.paddedTopLarge],
                    css[layout.paddedBottom],
                    css[layout.paddedBottom],
                    css[layout.paddedBottomLarge],
                    css[layout.paddedOverlap],
                    css[layout.paddedNoSides],
                    css[layout.marginBottomNegative],
                    css[layout.textCenter],
                    css[layout.slider],
                    css[maxWidth],
                    css[horizontal],
                    css[vertical],
                    className
                )}
            >
                {children}
            </div>
        );
    }
}

export const ForwardedContentWrapper = React.forwardRef((props, ref) => (
    <ContentWrapper {...props} innerRef={ref} />
));

export default ContentWrapper;
