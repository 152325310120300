import React from "react";

import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { withTrans } from "@i18n/withTrans";
import { graphql, useStaticQuery } from 'gatsby';
import { setPages, setLocale } from "@redux/actions";
import { LocaleContext } from 'gatsby-theme-i18n';

import { useDispatch } from "react-redux";
import SEO from "../../util/SOE/SOE";
import { useLocalization } from 'gatsby-theme-i18n';

const Layout = (props) => {
    const { children, pageContext, pageResources, i18n } = props;
    const dispatch = useDispatch();

    const { allSitePage } = useStaticQuery(graphql`
        query {
            allSitePage {
                nodes {
                    context {
                        id
                        originalPath
                    }
                }
            }
        }
    `);

    let pages = {};
    allSitePage.nodes.forEach(({ context }) => {
        pages[context.id] = context.originalPath;
    });

    dispatch(setPages(pages));
    dispatch(setLocale(pageContext.hrefLang));

    if (i18n.language !== pageContext.locale) {
        i18n.changeLanguage(pageContext.hrefLang);
    }

    return (
        <LocaleContext.Provider value={pageContext.locale}>
            <SEO pageContext={pageContext} />
            <Header />
            <main>{children}</main>
            <Footer parentComponentName={pageResources?.page?.componentChunkName ?? ''} />
        </LocaleContext.Provider>
    );
};

export default withTrans(Layout);
