import i18next from 'i18next';

export const initLanguages = ( { nl, en } ) => {
    i18next.init({
        resources: {
            nl: {
                translations: nl
            },
            en: {
                translations: en
            }
        },
        lng: 'nl',
        fallbackLng: 'en',
        ns: ['translations'],
        defaultNS: 'translations',
        returnObjects: true,
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false, // not needed for React
        },
        keySeparator: false,
        react: {
            wait: true,
        },
    });

    i18next.languages = ['nl', 'en'];
}

export default i18next;