import { createGlobalStyle } from '@nfront/global-styles';

const loadStyles = () => {
    const styles = {};
    styles.reset = require('./reset.global.scss').toString();
    styles.default = require('./default.global.scss').toString();

    return styles;
};

const styles = loadStyles();
const GlobalStyles = createGlobalStyle`
  ${styles.reset}
  ${styles.default}
`;

export default GlobalStyles;
