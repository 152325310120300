import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import RichContent from "@components/common/RichContent/RichContent";
import { graphql } from "gatsby";
import React, { Fragment } from "react";
import css from "@styles-pages/index.module.scss";
import labelcss from "@styles/label.module.scss";
import { ServerSideMetaTags } from "../components/util/SOE/SOE";

/* eslint-disable no-undef */
export const metadata = {
    query: 'getTestimonial',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const Page = (props) => {
    const { data } = props;

    const {
        testimonialpagetitle,
        testimonialintroduction,
        backgroundimage,
        text,
        stucturedData,
        Tags: labels
    } = data.pim.getTestimonial;

    return (
        <Fragment>
            <ServerSideMetaTags pim={data.pim} metadata={metadata} structureddata={stucturedData}/>
            <ContentWrapper isWide={true}>
                <Header image bgImage={backgroundimage} size="oneLiner">
                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                    >
                        <ContentWrapper
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >
                            <Heading isSemantic={true} variant="h1" color="white">
                                {testimonialpagetitle}
                            </Heading>
                            <HighlightedText color="white">
                                {testimonialintroduction}
                            </HighlightedText>
                            {labels && labels.length > 0 && (
                                <div className={labelcss.labelDiv}>
                                <p className={labelcss.labelsText}>Tags: </p>
                                    {labels.map((label) => (
                                        <div key={label.id} className={labelcss.whiteLabel}>
                                            {label.Tag}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </ContentWrapper>
                    </ContentWrapper>
                </Header>

                <ContentWrapper maxWidth="maxWidthSmall" paddedTop paddedBottom={true}>
                    <RichContent>{text}</RichContent>
                </ContentWrapper>
            </ContentWrapper>
        </Fragment>
    );
};

export default Page;

export const query = graphql`
    query($id: Int!, $hrefLang: String!) {
        pim {
            getTestimonial(id: $id, defaultLanguage: $hrefLang) {
                testimonialpagetitle
                testimonialintroduction
                backgroundimage {
                    id
                    fullpath
                }
                text
                seotitle
                seodescription
                stucturedData
                ogimage {
                    id
                    fullpath
                }
                Tags {
                    ... on PIM_object_Tag {
                        id
                        Tag
                    }
                }
            }
        }
    }
`;
