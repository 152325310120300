import React, { Component, Fragment } from 'react';
import i18next from './i18next';
import { I18nextProvider, withTranslation } from 'react-i18next';

import LanguageProvider from '@components/util/LanguageProvider';

export function withTrans(WrappedComponent) {
    WrappedComponent = withTranslation()(WrappedComponent);

    return class extends Component {
        render() {
            return (
                <Fragment>
                        <LanguageProvider>
                            <I18nextProvider i18n={i18next}>
                                <WrappedComponent {...this.props} language={i18next.language} />
                            </I18nextProvider>
                        </LanguageProvider>
                </Fragment>
            );
        }
    }
}