import React from 'react'
import css from "@styles-pages/landingspage.module.scss";
import classNames from "classnames";
import RichContent from '../common/RichContent/RichContent';

import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import Fade from '@components/util/Fade';

function CheckList(props) {
    let obj = props.data
    const SummaryIcon = () => {
        return <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 32C25.2843 32 32 25.2843 32 17C32 8.71573 25.2843 2 17 2C8.71573 2 2 8.71573 2 17C2 25.2843 8.71573 32 17 32Z" stroke="#00525D" stroke-width="2.25" />
            <path d="M11.75 17.75L14.75 20.75L22.25 13.25" stroke="#00525D" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    }
    return (
            <ul className={css.checklistUl}>
                {obj.map((item) => {
                    return (
                        <>
                            <li className={css.checklistLi}>
                                <details>
                                    <summary>
                                        <div className={css.flexbox}>
                                            <SummaryIcon />
                                            <div className={css.checkTitle}>
                                                {item.checkTitle} </div>
                                            <div className={css.arrowBox}><img className={css.arrow} src="/assets/icons/arrow-petrolgreen.svg" alt="Chevron" /> </div>
                                        </div>
                                    </summary>
                                    <Fade duration="1000">
                                        <div className={css.checkContent}>
                                        <RichContent>
                                            {item.checkContent}
                                        </RichContent>
                                        </div>

                                        <div className={css.checkContent2}>
                                            <div className={css.checkImageWrapper}>
                                                {item.chekImage && <img className={css.checkImage} alt="Checkmark" loading="lazy" src={generateThumbnailUrl(item.chekImage, "general_thumbnail")} />}

                                            </div>
                                            <div>
                                                <div className={css.checkContent2Title}>
                                                    {item.checkTitle2}
                                                </div>

                                                <RichContent>
                                                    {item.checkContent2}
                                                </RichContent>
                                            </div>

                                        </div>
                                    </Fade>

                                </details>
                            </li>
                        </>
                    )
                })}
            </ul>
    )
}

export default CheckList

//     margin-bottom: 1rem;
// font-family: "Alumni Sans", sans-serif;
// font-size: 3rem;
// line-height: 1;