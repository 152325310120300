import React, { Component } from 'react'
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";

import css from './Usp.module.scss';
import RichContent from "@components/common/RichContent/RichContent";

export class UspItem extends Component {
    render() {
        const { altTitle, uspText, uspImg} = this.props;
        return (
            <div className={css.uspItem}>
                <img alt={altTitle} loading="lazy" src={generateThumbnailUrl(uspImg, "general_thumbnail")} />
                <div >
                    <RichContent>{ uspText }</RichContent>
                </div>
            </div>
        )
    }
}

export default UspItem