/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component, Fragment } from "react";

import css from "./ContactForm.module.scss";
import { __ } from "../../../../i18n/util";
import { graphql, StaticQuery } from "gatsby";
import { useTranslation, Translation } from "react-i18next";
import { stringToUrl } from '@util/string-to-url';
import Dropzone from 'react-dropzone'

import ReCAPTCHA from "react-google-recaptcha";
import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import classNames from "classnames";


/* When using this form you can pick the variants: newsletter, vacancyLetter, vacancyContact or contact */
class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            didUserSend: false,
            isMessageSendSuccessfully: undefined,
            enableSubmit: false
        };


        this.onDrop = (files) => {
            if(files[0].type === 'application/pdf'){
                this.setState({ files })
            }
        };
        this.state = {
            files: []
        };

        this.recaptchaRef = React.createRef();
        this.msgBlock = React.createRef();
        this.fileInput = React.createRef();
    }

    /* handlers */
    onSubmit = (event) => {
        event.preventDefault();
        if (this.recaptchaRef.current.getValue() === "") {
            return;
        }

        const data = new FormData(event.target);
        this.setState({ didUserSend: true });

        fetch(this.props.endpoint, {
            method: "POST",
            body: data
        })
            .then((res) => res.json())
            .then((response) => {
                this.setState({ isMessageSendSuccessfully: true });
                this.scrollToBlock();
            })
            .catch((error) => {
                this.setState({ isMessageSendSuccessfully: false });
                this.scrollToBlock();
            });
    };

    resetForm = (event) => {
        event.preventDefault();
        this.setState({
            didUserSend: false,
            isMessageSendSuccessfully: undefined,
        });
    };

    scrollToBlock = () => {

        const element = this.msgBlock.current;
        const topPos = element.offsetTop;

        if ('undefined' !== window) {
            window.scrollTo({
                top: topPos - 150,
                left: 0,
                behavior: 'smooth',
            });
        }
    }

     removeAll = () => {
        this.setState({ files: []})
      }


    /* render */
    renderVariant() {

        const files = this.state.files.map(file => (
            <span  className={css.fileItem} key={file.name}>
                {file.name}
            </span>
        ));

        return this.props.isVacancy ? (
            <Dropzone
                onDrop={this.onDrop}
                maxFiles={1}
            minSize={1}
            maxSize={5000000}
            >
                {({
                    getRootProps,
                    getInputProps,
                    isDragActive
                }) => (
                    <section>
                        <label htmlFor="resume">{__("Curriculum vitae")}</label>
                        <div>
                            <div {...getRootProps()}
                                className={classNames(css.curriculum, (isDragActive ? css.curriculumDrag : ''))}>
                                <input {...getInputProps()} />

                                {files.length > 0 ?
                                    <div className={css.fileList}>{files}</div> :
                                    <img
                                        className={css.iconUpload}
                                        loading="lazy"
                                        alt="fileupload"
                                        src="/assets/icons/fileupload.svg"
                                    />
                                }
                            </div>
                            <div className={css.info}>
                                <span>max: 5mb, enkel PDF</span>
                                {files.length > 0 ?
                                    <span className={css.iconRemove} role="button" onClick={this.removeAll}>
                                        <span className={css.label}>Remove</span>
                                        <img
                                            className={css.icon}
                                            loading="lazy"
                                            alt="Verwijder bestand"
                                            src="/assets/icons/trash.svg"
                                        /></span> :
                                    ""
                                }
                            </div>

                        </div>
                    </section>
                )}
            </Dropzone>
        ) : !(this.props.variant === "vacancyLetter") ? (
            <React.Fragment>
                <label htmlFor="company">{__("Company")}</label>
                <input name="company" type="text" />
            </React.Fragment>
        ) : <></>;

    }

    render() {
        const { successMessage, errorMessage, resetMessage, variant } = this.props;
        const { didUserSend, isMessageSendSuccessfully } = this.state;

        let isForNewsletter = false
        let isForVacancyContact = false
        let isForVacancyLetter = false

        switch (variant) {
                case "newsletter":
                    isForNewsletter = true;
                    break;
                case "vacancyContact":
                    isForVacancyContact = true;
                    break;
                case "vacancyLetter":
                    isForVacancyLetter = true;
                    break;
                default:
                    break;
            }

        const InputButton = (props) => {
            const { t, i18n } = useTranslation();

            return (
                <input
                    type="submit"
                    disabled={!props.enableSubmit}
                    className={css.submit}
                    value={(!isForNewsletter && !isForVacancyLetter) ? t('Send form') : t('Subscribe')}
                />
            )
        }

        // if user didn't (hasn't yet) send | default state
        if (!didUserSend || isMessageSendSuccessfully === undefined) {
            return (
                <StaticQuery
                    query={graphql`
                        query TranslationQuery {
                            pim {
                                getPage(id: 96, defaultLanguage: "auto") {
                                    pageIntroTitle
                                }
                            }
                        }
                    `}
                    render={data => (
                        <section className={css.form}>
                            <form onSubmit={this.onSubmit.bind(this)} encType="multipart/form-data">

                                <h2>{isForNewsletter ? __("Subscribe for newsletter")
                                    : isForVacancyLetter ? __("Stay up to date with our job vacancies")
                                    : __("Contactform")}</h2>
                                <div>
                                    <label htmlFor="firstName">{__("Firstname")}</label>
                                    <input
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        required="required"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="lastName">{__("Lastname")}</label>
                                    <input
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        required="required"
                                    />
                                </div>
                                {
                                    isForVacancyContact &&
                                    (
                                        <Fragment>
                                            <label htmlFor="vacancies">{__("Function")}:</label>
                                            <select required="required" id="vacancies" name="vacancies">
                                                <Translation>
                                                    { (t) => <option value="spontaneSollicitatie">{ t('Spontaneous sollicitation') }</option> }
                                                </Translation>
                                                {
                                                    this.props.jobVacancies.map(
                                                        (vacancy) => <option value={vacancy.node.functiontitle}>{vacancy.node.functiontitle}</option>
                                                    )
                                                }
                                            </select>
                                        </Fragment>
                                    )
                                }
                                <div>{this.renderVariant()}</div>
                                <input
                                    id="formType"
                                    type="hidden"
                                    name="formName"
                                    value={variant}
                                />

                                {(isForVacancyContact) &&
                                <div className={css.fullWidth}>
                                    <label htmlFor="motivationLetter">{__("Motivation letter")}</label>
                                    <textarea
                                        id="motivationLetter"
                                        name="motivationLetter"
                                        type="textarea"
                                        required="required"
                                    />
                                </div>}

                                <div>
                                    <label htmlFor="phone">
                                        {__("Phone")}
                                        <span>{__("(Optional)")}</span>
                                    </label>
                                    <input
                                        id="phone"
                                        name="phone"
                                        type="tel"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="email">
                                        {__("E-mail address")}
                                    </label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        required="required"
                                    />
                                </div>
                                {(!isForNewsletter && !isForVacancyLetter) &&
                                    <div className={css.fullWidth}>
                                        <label htmlFor="inquiry">{__("Message")}</label>
                                        <textarea
                                            id="message"
                                            name="inquiry"
                                            type="textarea"
                                            required="required"
                                        />
                                    </div>}
                                <div className={css.formCheckbox}>
                                    <input type="checkbox" id="privacy" name="privacy" value="privacy" required="required" />
                                    <label for="privacy">{__("I have read and accepted the")} <a target="_blank" rel="noopener noreferrer" href={'/' + stringToUrl(data.pim.getPage.pageIntroTitle)}>{__("privacy conditions")}</a> {__("read and accepted")}</label>
                                </div>
                                {(!isForNewsletter && !isForVacancyLetter && !isForVacancyContact)&&
                                    <div className={css.formCheckbox}>
                                        <input type="checkbox" id="marketingOptIn" name="marketingOptIn" value="marketingOptIn" />
                                        <label for="marketingOptIn">{__("I would like to receive newsletters")}</label>
                                    </div>}
                                    {isForVacancyContact &&
                                    <div className={css.formCheckbox}>
                                        <input type="checkbox" id="vacancyOptIn" name="vacancyOptIn" value="vacancyOptIn" />
                                        <label for="vacancyOptIn">{__("Keep me up to date with new job vacancies at Studio Emma")}</label>
                                    </div>}
                                <div className={css.captcha}>
                                    <ReCAPTCHA
                                        ref={this.recaptchaRef}
                                        sitekey="6LebFiAdAAAAAN2ATpQ2KK17VThRcQRl97iCrrf5"
                                        onChange={(value) => {
                                            this.setState({
                                                enableSubmit: value !== ''
                                            })
                                        }}
                                    />
                                </div>
                                <div className={css.buttonContainer}>
                                    <InputButton enableSubmit={this.state.enableSubmit} />
                                </div>
                            </form>
                        </section>
                    )}
                />
            );
        }

        let msg = "";

        if (didUserSend) {
            if (isMessageSendSuccessfully) {
                msg = successMessage
            }
            else { msg = errorMessage }

            return (
                <ContentWrapper maxWidth="maxWidthSmall">
                    <div ref={this.msgBlock}>
                        <div>{msg}</div>
                        <button
                            type="button"
                            className={css.button}
                            onClick={this.resetForm}
                        >
                            {resetMessage}
                        </button>
                    </div>
                </ContentWrapper>
            );

        }
    }
}

export default ContactForm;
