import React from 'react'
import css from './BubblesBg.module.scss';

function BubblesBg(props) {
    return (
        <>
            {(props.active) && <div className={css.bubbles}>
                <div className={css.bubble}></div>
                <div className={css.bubble}></div>
                <div className={css.bubble}></div>
                <div className={css.bubble}></div>
                <div className={css.bubble}></div>
                <div className={css.bubble}></div>
                <div className={css.bubble}></div>
                <div className={css.bubble}></div>
                <div className={css.bubble}></div>
                <div className={css.bubble}></div>
                <div className={css.bubble}></div>
                <div className={css.bubble}></div>
                <div className={css.bubble}></div>
                <div className={css.bubble}></div>
                <div className={css.bubble}></div>
            </div>}
        </>
    )

}

export default BubblesBg