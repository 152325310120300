import { store } from '@redux/provider';

const iterateObject = function (object, transformer) {
    for (const key in object) {
        let val = object[key];
        if (typeof val === 'object') {
            val = iterateObject(val, transformer);
        }
        let newKey = transformer(key);
        object[newKey || key] = val;
    }

    return object;
}

// export const
export const useLocale = (data, autoHash, localeHash, queryContext) => {
    const localizedData = data;
    const { locale } = store.getState();

    iterateObject(localizedData, (key) => {
        if (key.includes('__nl_BE__') || key.includes('__en__')) {
            if (locale === 'nl_BE') {
                return key.replace('__nl_BE__', '');
            }

            if (locale === 'en') {
                return key.replace('__en__', '');
            }

            return key;
        }
    });

    return data;
}

export default useLocale;