import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import css from "../../vacancies/ImageSlider/ImageSlider.module.scss";
import classNames from "classnames";

export const GenericSlider = (props) => {
    const { width, children, className } = props;

    const settings = (width === "fixed")
        ? {
            infinite: true,
            lazyLoad: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            // speed: 6000,
            pauseOnHover: false,
            arrows: true,
            responsive: [

                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        arrows: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 830,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 510,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                    }
                }
            ]
        }
        : {
            infinite: true,
            lazyLoad: false,
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            centerMode: true,
            arrows: false,
            variableWidth: true,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 320,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                        arrows: false,
                        variableWidth: false,
                        adaptiveHeight: false,
                    }
                }
            ]
        };

    return (
        <div>
            <div className={classNames(css.container, css[width], className)}>
                <Slider {...settings}>
                    { children }
                </Slider>
            </div>
        </div>

    );
}

export default GenericSlider;
