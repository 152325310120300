import React, { Component } from 'react'

import css from './LinkButtonRow.module.scss';

export class LinkButtonRow extends Component {
    render() {
        const { children } = this.props;
        return (
            <div className={css.linkButtonRow}>
                {
                    children.map((child,index) => <div className={css.linkButtonRowItem} key={index}>{child}</div> )
                    
                }
            </div>
        )
    }
}

export default LinkButtonRow
