import React, { Component } from 'react';

import { Controller, Scene } from 'react-scrollmagic';

import { connect } from 'react-redux';
import { setCurrentService, setServiceScrollDirection } from '@redux/actions';
import Service from '../Service/Service';
import ServiceHandler from './ServiceHandler';

export class ServiceController extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        const { position } = this.props;

        return (
            <Controller
                refreshInterval={1000}
            >
                <Scene
                    duration="100%"
                    pin={false}
                    reverse={true}
                    indicators={false}
                >
                    {(progress, event) => {
                        const index = position - 1;
                        return (<div data-trigger={true}><ServiceHandler event={event} progress={progress} index={index} /></div>);
                    }}
                </Scene>
                <Service {...this.props} />
            </Controller>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setCurrentService: (step) => dispatch(setCurrentService(step)),
    setServiceScrollDirection: (direction) => dispatch(setServiceScrollDirection(direction))
});

const mapStateToProps = ({ currentService }) => ({ currentService });

export default connect(mapStateToProps, mapDispatchToProps)(ServiceController);
