import React from 'react';
import OriginalFade from 'react-reveal/Fade';
import classNames from "classnames";
import css from "@styles-pages/portfolio.module.scss";

export const Fade = ({ duration, children }) => {
    const fadeDuration = duration || 2000;

    if (typeof window === 'undefined') {
        return (
            <div class={classNames(css['ssr'])}>
                { children }
            </div>
        );
    }

    return (
        <OriginalFade ssrFadeout={true} duration={fadeDuration}>
            <div>
                { children }
            </div>
        </OriginalFade>
    );
}

export default Fade;