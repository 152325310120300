import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import css from "@styles-pages/index.module.scss";
import { ServerSideMetaTags } from "../components/util/SOE/SOE";

import Header from '@components/common/Header/Header';
import Heading from '@components/common/Heading/Heading';
import ContentWrapper from '@components/common/ContentWrapper/ContentWrapper';
import HighlightedText from '@components/common/HighlightedText/HighlightedText';
import RichContent from "@components/common/RichContent/RichContent";

/* eslint-disable no-undef */
export const metadata = {
    query: 'getErrorNotFound',
    title,
    description,
    image
};

const ErrorNotFoundQuery = () => {
    const { pim } = useStaticQuery(graphql`
        query {
            pim {
                getErrorNotFound(id: 341, defaultLanguage: "auto") {
                  pageIntroTitle
                  pageIntroSubtitle
                  pageContent

                  seotitle
                  seodescription
                  ogimage {
                    id
                    fullpath
                  }
                }
            }
        }
    `);

    const { pageIntroTitle, pageIntroSubtitle, pageContent } = pim.getErrorNotFound;

    return (
        <ContentWrapper isWide={true}>
            <ServerSideMetaTags pim={pim} metadata={metadata}/>
            <Header image={true} size="oneLiner">
                <ContentWrapper
                    isWide={false}
                    className={css.contentWrapper}
                >
                    <ContentWrapper
                        isWide={true}
                        maxWidth="maxWidthSmall"
                    >
                        <Heading
                            isSemantic={true}
                            variant="h1"
                            color="white"
                        >
                            {pageIntroTitle}
                        </Heading>
                        <HighlightedText color="white">
                            {pageIntroSubtitle}
                        </HighlightedText>
                    </ContentWrapper>
                </ContentWrapper>
            </Header>
            <ContentWrapper
                paddedTop
                paddedBottom
            >
                <RichContent>
                    {pageContent}
                </RichContent>
            </ContentWrapper>
        </ContentWrapper>
    );
};

const Page = () => {
    return <ErrorNotFoundQuery />;
};

export default Page;