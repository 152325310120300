import React, { Component } from "react";
import css from "./Header.module.scss";
import throttle from "lodash.throttle";

import Menu from "./Menu/Menu";
import Logo from "./Menu/Logo/Logo";
import classNames from "classnames";

import { setIsStickyNavigationOpen as setIsStickyNavigationOpenAction } from "@redux/actions";
import { connect } from "react-redux";

export class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastScrollTop: 0,
        };
    }

    isScrollingDown = () => {
        let scrollingDistanceTop =
            window.pageYOffset || document.documentElement.scrollTop;
        if (scrollingDistanceTop > this.state.lastScrollTop) {
            this.props.setIsStickyNavigationOpen(false);
        } else {
            const isScrolling =
                document.body.scrollTop > 500 ||
                document.documentElement.scrollTop > 500;
            this.props.setIsStickyNavigationOpen(isScrolling);
        }
        this.setState({
            lastScrollTop: scrollingDistanceTop <= 0 ? 0 : scrollingDistanceTop,
        });
    };

    componentDidMount() {
        if (typeof window !== "undefined") {
            window.addEventListener(
                "scroll",
                throttle(this.isScrollingDown.bind(this), 250)
            );
        }
    }

    render() {
        const { isStickyNavigationOpen } = this.props;
        const stickyCssClasses = (typeof window !== 'undefined' && isStickyNavigationOpen)
            ? {
                [css.sticky]: window.innerWidth > 1000,
                "header-sticky": window.innerWidth > 1000,
                [css.mobileSticky]: window.innerWidth < 1000
            }
            : {};

        return (
            <>
                <header className={classNames(css.navigation, stickyCssClasses)}>
                    <div className={classNames(css.navigationContainer, (typeof window !== "undefined" && { [css.mobileStickyContainer]: (window.innerWidth < 1000 && isStickyNavigationOpen) }))}>
                        <Logo useDefaultTheme={(typeof window !== "undefined" && isStickyNavigationOpen && window.innerWidth > 1000) ? true : false} />
                        <Menu useDefaultTheme={(typeof window !== "undefined" && isStickyNavigationOpen && window.innerWidth > 1000) ? true : false} />
                    </div>
                </header>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setIsStickyNavigationOpen: (boolean) =>
        dispatch(setIsStickyNavigationOpenAction(boolean)),
});

const mapStateToProps = (globalState) => ({
    isStickyNavigationOpen: globalState.isStickyNavigationOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
