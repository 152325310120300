const syspath = require("path");

const _kebabCase = (string) =>
    string
        .match(/[A-Z]{2,}(?=[A-Z][a-z0-9]*|\b)|[A-Z]?[a-z0-9]*|[A-Z]|[0-9]+/g)
        .filter(Boolean)
        .map((x) => x.toLowerCase())
        .join("-");

module.exports.kebabCase = _kebabCase;

const _kebabCase2 = (string) => {
    if (!string || string === '') return '';

    // Remove leading / if present
    if (string.startsWith('/')) {
        string = string.substring(1);
    }

    return string
        .match(/\/|[A-Z]{2,}(?=[A-Z][a-z0-9]*|\b)|[A-Z]?[a-z0-9]*|[A-Z]|[0-9]+/g)
        .filter(Boolean)
        .map((x) => x.toLowerCase())
        .join("-")
        .replace(/-?\/-?/g, '/'); // Replace -/-, -/, or /- with /
};

module.exports.kebabCase2 = _kebabCase2;

const _routes = [
    {
        id: 'pages',
        query: 'getPageListing',
        identifier: 'id',
        slug: 'seoPageTitle', // 'pageTitle',
        template: 'generic-page.js',
        locale: {
            nl_BE: {
                directory: '' /* '' directory -> root */
            },
            en_US: {
                directory: '' /* '' directory -> root */
            }
        }
    },
    {
        id: 'vacancies',
        query: 'getVacancyListing',
        identifier: 'id',
        slug: 'seotitle', // 'functiontitle',
        template: 'vacancy-post.js',
        locale: {
            nl_BE: {
                directory: 'jobs'
            },
            en_US: {
                directory: 'jobs'
            }
        }
    },
    {
        id: 'posts',
        query: 'getNewsPostListing',
        identifier: 'id',
        slug: 'seotitle', // 'Title',
        template: 'news-post.js',
        locale: {
            nl_BE: {
                directory: 'blog'
            },
            en_US: {
                directory: 'blog'
            }
        }
    },
    {
        id: 'projects',
        query: 'getProjectListing',
        identifier: 'id',
        slug: 'seotitle', // 'projecttitle',
        template: 'project-post.js',
        locale: {
            nl_BE: {
                directory: 'portfolio'
            },
            en_US: {
                directory: 'portfolio'
            }
        }
    },
    {
        id: 'projectsNew',
        query: 'getProjectnewListing',
        identifier: 'id',
        slug: 'seotitle', // 'projecttitle',
        template: 'project-post-new.js',
        locale: {
            nl_BE: {
                directory: 'portfolio'
            },
            en_US: {
                directory: 'portfolio'
            }
        }
    },
    {
        id: 'testimonials',
        query: 'getTestimonialListing',
        identifier: 'id',
        slug: 'seotitle', // 'title',
        template: 'testimonial-post.js',
        locale: {
            nl_BE: {
                directory: 'testimonial'
            },
            en_US: {
                directory: 'testimonial'
            }
        }
    },
    {
        id: 'teammembers',
        query: 'getTeamMemberListing',
        identifier: 'id',
        slug: 'seotitle', // 'name',
        template: 'team-post.js',
        locale: {
            nl_BE: {
                directory: 'team'
            },
            en_US: {
                directory: 'team'
            }
        }
    },
    {
        id: 'webinars',
        query: 'getWebinarListing',
        identifier: 'id',
        slug: 'seotitle', // 'name',
        template: 'webinar-post.js',
        locale: {
            nl_BE: {
                directory: 'webinars'
            },
            en_US: {
                directory: 'webinars'
            }
        }
    },
    {
        id: 'services',
        query: 'getFeaturedServiceListing',
        identifier: 'id',
        slug: 'URL', // 'functiontitle',
        template: 'featured-service.js',
        locale: {
            nl_BE: {
                directory: '' /* '' directory -> root */
            },
            en_US: {
                directory: '' /* '' directory -> root */
            }
        }
    },
    {
        id: 'landingsPage',
        query: 'getLandingpageListing',
        identifier: 'id',
        slug: 'seoPageTitle', // 'functiontitle',
        template: 'landingspage.js',
        locale: {
            nl_BE: {
                directory: '' /* '' directory -> root */
            },
            en_US: {
                directory: '' /* '' directory -> root */
            }
        }
    },
    {
        id: 'partnerDetail',
        query: 'getTechnologyLinkListing',
        identifier: 'id',
        slug: 'seotitle', // 'functiontitle',
        template: 'partner-detail.js',
        locale: {
            nl_BE: {
                directory: '' /* '' directory -> root */
            },
            en_US: {
                directory: '' /* '' directory -> root */
            }
        }

    }
];

module.exports._routes = _routes;

module.exports.query = `
    query {
        pim {
            ${ _routes.map(({ id, query, identifier, slug }) => `${id}: ${query}(defaultLanguage: "auto") { edges { node { ${identifier} ${slug} } } } `)}
        }
    }
`;

module.exports.createPageGenerator = (
    actions
) => (
    pagesQuery,
    config = {},
    lang = 'nl_BE'
) => {
    const generatedRoutes = [];

    for (const index in _routes) {
        const route = _routes[index];
        const { directory } = route.locale[lang];
        const group = (directory && directory !== '') ? `/${route.locale[lang].directory}/` : '';
        const pages = pagesQuery.data.pim[route.id].edges;



        for (const index in pages) {
            const page = pages[index].node;

            const pathIdentifier =  `-${page[route.identifier]}`;
            const pathSlug = `${page[route.slug]}`;
            const pathUnique = `${group}${_kebabCase(`${pathSlug}${pathIdentifier}`)}`;
            const pathShorthand = `${group}${_kebabCase(`${pathSlug}`)}`;
            const doesPathExists = generatedRoutes.includes(pathShorthand);

            const path = (doesPathExists) ? pathUnique : pathShorthand;

            if (page[route.slug] === null || page[route.slug] === undefined || path === '') continue;
            console.log('Generating path:', path);
            actions.createPage({
                path: path,
                component: syspath.resolve(`./src/templates/${route.template}`),
                context: {
                    id: parseInt(page[route.identifier])
                }
            });

            generatedRoutes.push(pathShorthand);
        }
    }
};