import React from "react";

import css from "./Copyright.module.scss";
import { __ } from "@i18n/util";
import { useStaticQuery, graphql } from "gatsby";

const Copyright = () => {
    const { buildTimeVersion } = useStaticQuery(graphql`
        query {
            buildTimeVersion
        }
    `);

    const [ timestamp ] = buildTimeVersion;

    return (
        <div className={css.container}>
            <p className={css.text}>
                {" "}
                {__("© 2002 - {{year}} Studio Emma | All rights reserved", {
                    year: new Date().getUTCFullYear(),
                })}{" "}
            </p>
            <small>v:{timestamp}</small>
        </div>
    );
}

export default Copyright;
