import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import ColumnGroup from "@components/common/ColumnGroup/ColumnGroup";
import Column from "@components/common/Column/Column";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import { graphql } from "gatsby";
import React, { Fragment } from "react";
import css from "@styles-pages/team-post.module.scss";
import LinkButton from "@components/common/LinkButton/LinkButton";
import { __ } from "@i18n/util";
import urlBuilder from "@util/url-builder";

import { ServerSideMetaTags } from "../components/util/SOE/SOE";

/* eslint-disable no-undef */
export const metadata = {
    query: 'getTeamMember',
    title,
    description,
    image
};

const Page = (props) => {
    const { data } = props;

    const {
        expertise,
        name,
        portrait,
        skills,
        biotext,
        characteristictext,
        leisuretext,
    } = data.pim.getTeamMember;

    const {
        biotitle,
        characteristictitle,
        technicalspecstitle,
        leisuretitle,
    } = data.pim.getTeamConfigListing.edges[0].node;

    return (
        <Fragment>
            <ServerSideMetaTags pim={data.pim} metadata={metadata} />
            <ContentWrapper isWide={true}>
                <Header size="oneLiner" image={true}>
                    <ContentWrapper
                        vertical="center"
                        maxWidth="maxWidthSmall"
                    >
                        <div className={css.header}>
                            <div className={css.headerText}>
                                <Heading
                                    isSemantic={true}
                                    variant="h1"
                                    color="white"
                                >
                                    {name}
                                </Heading>
                                <HighlightedText color="white">
                                    {expertise}
                                </HighlightedText>
                            </div>
                        </div>
                    </ContentWrapper>
                </Header>
                <ContentWrapper paddedTop={true} paddedBottom={true} maxWidth="maxWidthMedium">
                    <ColumnGroup halign="halignbetween">
                        <Column widthmd="widthsm100" widthlg="widthmd30" className={css.picture}>
                            <img
                                loading="lazy"
                                alt={name}
                                src={generateThumbnailUrl(
                                    portrait,
                                    "team_member_thumbnail"
                                )}
                            />
                        </Column>
                        <Column widthmd="widthsm100" widthlg="widthmd60" className={css.info}>
                            <h3 className={css.subTitle}>{biotitle}</h3>
                            <p className={css.bio}>{biotext}</p>
                            <h3 className={css.subTitle}>{characteristictitle}</h3>
                            <p className={css.text}>{characteristictext}</p>
                            <h3 className={css.subTitle}>{leisuretitle}</h3>
                            <p className={css.text}>{leisuretext}</p>
                            <h3 className={css.subTitle}>{technicalspecstitle}</h3>
                            <ul className={css.list}>
                                {skills.map((s) => {
                                    return (
                                        <li key={s.id} className={css.listItem}>
                                            {s.Tag}
                                        </li>
                                    );
                                })}
                            </ul>
                            <LinkButton
                                text={__("All team members")}
                                link={urlBuilder.buildStaticUrl('team')}
                                uppercase={false}
                            />
                        </Column>
                    </ColumnGroup>
                </ContentWrapper>
            </ContentWrapper>
        </Fragment>
    );
};

export default Page;

export const query = graphql`
    query($id: Int!, $hrefLang: String!) {
        pim {
            getTeamMember(id: $id, defaultLanguage: $hrefLang) {
                expertise
                id
                name
                portrait {
                    id
                    fullpath
                }
                seotitle
                seodescription
                ogimage {
                    id
                    fullpath
                }
                skills {
                    ... on PIM_object_Tag {
                        id
                        Tag
                    }
                }
                biotext
                characteristictext
                leisuretext
            }
            getTeamConfigListing(defaultLanguage: $hrefLang) {
                edges {
                    node {
                        biotitle
                        characteristictitle
                        technicalspecstitle
                        leisuretitle
                    }
                }
            }
        }
    }
`;
