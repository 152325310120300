import React from "react";
import css from "./ToggleSwitch.module.scss"
import classNames from 'classnames';

const ToggleSwitch = ({checked, onChange, disabled, id}) => (
      <div className={`${css.toggleSwitch}`}>
        <input
          type="checkbox"
          className={`${css.toggleSwitchCheckbox}`}
          id={id}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
        />
        <label className={`${css.toggleSwitchLabel}`} htmlFor={id}>
          <span className={
              disabled
                ? classNames(css.toggleSwitchInner, css.toggleSwitchDisabled)
                : `${css.toggleSwitchInner}`
              } />
          <span className={
              disabled
                ? classNames(css.toggleSwitchSwitch, css.toggleSwitchDisabled)
                : `${css.toggleSwitchSwitch}`
              } />
        </label>
      </div>
);

export default ToggleSwitch;