import React from "react";
import { generateLayout } from "@util/generate-layout";
import css from "./Button.module.scss";
import classNames from "classnames";

export class Button extends React.Component {
    //button should have 3 styles (variants) and a buttontext
    render() {
        const { variant, text, uppercase, marginTop, marginSides, onClick } = this.props;

        const layout = generateLayout({
            marginTop,
            marginSides
        });

        let textTransform = "none";
        if (uppercase) {
            textTransform = "uppercase";
        }

        return (
                <div onClick={onClick} className={classNames(css.button, css[variant], css[layout.marginTop], css[layout.marginSides])}>
                    <span
                        className={classNames(
                            css.text,
                            css[textTransform]
                        )}
                    >
                        {text}
                    </span>
                </div>
        );
    }
}

export default Button;
