

import React, { Component } from "react";

import { LocalizedLink as Link } from "gatsby-theme-i18n";
import css from "./Links.module.scss";

import { useStaticQuery, graphql } from "gatsby";
import { stringToUrl } from '@util/string-to-url';

const LinksQuery = () => {
    const { pim } = useStaticQuery(graphql`
        query {
            pim {
                getPageListing(defaultLanguage: "auto", filter: "{\"$or\": [{\"pageReference\": \"Cookiepolicy\"}, {\"pageReference\": \"privacy\"}, {\"pageReference\": \"conditions\"} ]}") {
                    edges {
                        node {
                            pageUrlKey
                            pageIntroTitle
                        }
                    }
                }
            }
        }
  `);

    const links = pim.getPageListing.edges.map(({ node }) => ({
        text: node.pageIntroTitle,
        url: node.pageIntroTitle,
    }));

    return <Links links={links} />;
};

export class Links extends Component {
    render() {
        const { links } = this.props;

        return (
            <div className={css.links}>
                {/* TODO routing */}
                {links.map((link, index) => (
                    <Link key={index} to={"/" + stringToUrl(link.url)} title={link.text}>
                        {link.text}
                    </Link>
                ))}
            </div>
        );
    }
}

export default LinksQuery;
