import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import LinkButton from "@components/common/LinkButton/LinkButton";
import RichContent from "@components/common/RichContent/RichContent";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import css from "@styles-pages/news-post.module.scss";
import labelcss from "@styles/label.module.scss";
import { graphql } from "gatsby";
import React from "react";
import { __ } from "../../i18n/util";
import { NewsMeta } from "../components/news/NewsMeta/NewsMeta";
import urlBuilder from "@util/url-builder";
import { ServerSideMetaTags } from "../components/util/SOE/SOE";

/* eslint-disable no-undef */
export const metadata = {
    query: 'getNewsPost',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const Page = (props) => {
    const { data } = props;

    const {
        Author: author,
        Post: post,
        PublishDate: date,
        Title: title,
        IntroText,
        stucturedData,
        Tags: labels
    } = data.pim.getNewsPost;

    return (
        <>
            <ServerSideMetaTags pim={data.pim} metadata={metadata} structureddata={stucturedData}/>
            <ContentWrapper isWide={true}>
                <Header image={true} size="oneLiner">
                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                    >
                        <ContentWrapper
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >
                            <NewsMeta date={date} author={author} />
                            <Heading isSemantic={true} variant="h1" color="white">
                                {title}
                            </Heading>
                            <HighlightedText color="white">
                                {IntroText}
                            </HighlightedText>
                            {labels && labels.length > 0 && (
                                <div className={labelcss.labelDiv}>
                                    <p className={labelcss.labelsText}>Tags: </p>
                                    {labels.map((label) => (
                                        <div key={label.id} className={labelcss.whiteLabel}>
                                            {label.Tag}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </ContentWrapper>
                    </ContentWrapper>

                </Header>
                <ContentWrapper paddedTop={true} paddedBottom={true} maxWidth="maxWidthSmall">
                    <RichContent>{post}</RichContent>
                </ContentWrapper>
                <ContentWrapper paddedBottom={true}>
                    <div className={css.btnContainer}>
                        {/* <Link to="/blog" className={css.backBtn}>
                            {__("All news")}
                        </Link> */}
                        <LinkButton
                            link={urlBuilder.buildStaticUrl('blog')}
                            variant="blue"
                            text={__("All news")}
                        />
                    </div>
                </ContentWrapper>
            </ContentWrapper>
        </>
    );
};

export default Page;

export const query = graphql`
    query($id: Int!, $hrefLang: String!) {
        pim {
            getNewsPost(id: $id, defaultLanguage: $hrefLang) {
                Author
                Post
                PublishDate
                Title
                IntroText
                seotitle
                seodescription
                stucturedData
                ogimage {
                    id
                    fullpath
                }
                Tags {
                    ... on PIM_object_Tag {
                        id
                        Tag
                    }
                }
            }
        }
    }
`;