import React from 'react';
import css from "@styles-pages/index.module.scss";

import Header from '@components/common/Header/Header';
import Heading from '@components/common/Heading/Heading';
import ContentWrapper from '@components/common/ContentWrapper/ContentWrapper';
import HighlightedText from '@components/common/HighlightedText/HighlightedText';

import { __ } from "@i18n/util";

/* eslint-disable no-undef */
export const metadata = {
    title : 'Page not found!',
    description : 'Looks you like the page you requested doesn\'t exist!',
    image: undefined
};

const Page = () => {
    return (
        <ContentWrapper isWide={true}>
            <Header image={true} size="oneLiner">
                <ContentWrapper
                    isWide={false}
                    className={css.contentWrapper}
                >
                    <ContentWrapper
                        isWide={true}
                        maxWidth="maxWidthSmall"
                    >
                        <Heading isSemantic={true} variant="h1" color="white">
                            { __('Page not found!') }
                        </Heading>
                        <HighlightedText color="white">
                            { __('Gatsby.js development 404 page') }
                        </HighlightedText>
                    </ContentWrapper>
                </ContentWrapper>
            </Header>
            <ContentWrapper
                paddedTop={true}
                paddedBottom={true}
            >
                <Heading isSemantic={true} variant="h2" color="black">
                    { __('This page doesn\'t exist yet') }
                </Heading>
                <p>
                    Create a React.js component in your site directory at
                    <span style={{ fontWeight: 700 }}>src/pages/</span>
                    and this page will automatically refresh to show the new page component you created.
                </p>
            </ContentWrapper>
        </ContentWrapper>
    );
};

export default Page;
