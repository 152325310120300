/* eslint eqeqeq: 0 */

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { __ } from "@i18n/util";
import { generateThumbnailUrl } from "@util/thumbnail_url_generator";
import css from "@styles-pages/index.module.scss";
import urlBuilder from "@util/url-builder";

import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import Header from "@components/common/Header/Header";
import Card from "@components/common/Card/Card";
import CardGrid from "@components/common/CardGrid/CardGrid";
import Heading from "@components/common/Heading/Heading";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import LinkButton from "@components/common/LinkButton/LinkButton";
import LinkButtonRow from "@components/common/LinkButtonRow/LinkButtonRow";
import ServicesGrid from "@components/homepage/ServicesGrid/ServicesGrid";
import FeaturedServiceGrid from "@components/services/FeaturedServiceGrid/FeaturedServiceGrid";
import Article from "@components/common/Article/Article";
import ImageSlider from "@components/vacancies/ImageSlider/ImageSlider";
import ImageSlider2 from "@components/vacancies/ImageSlider/ImageSlider";
import { checkButton } from '@util/check-button';
import { ServerSideMetaTags } from "../components/util/SOE/SOE";
import Fade from '@components/util/Fade';
import DownloadCta from "../components/downloadCta/DownloadCta";
import ReviewFader from "../components/reviewFader/ReviewFader";
import FloatingIcons from "../components/rebrand/FloatingIcons";
import BubblesBg from "../components/rebrand/BubblesBg";
import GenericSlider from "../components/common/GenericSlider/GenericSlider";
import PartnerSliderItem from "../components/common/Partners/PartnerSliderItem";

/* eslint-disable no-undef */
export const metadata = {
    query: 'getHomeConfigListing',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const HomeQuery = () => {
    const { pim } = useStaticQuery(graphql`
        query {
            pim {
                getHomeConfigListing(defaultLanguage: "auto") {
                    edges {
                        node {
                            ShowOnHomepage2
                            temporaryURL2
                            temporarySectionTitle2
                            temporarySectionText2
                            temporaryPicture2 {
                                id
                                fullpath
                            }
                            temporaryButtonTitle2
                            seotitle
                            seodescription
                            stucturedData
                            ogimage {
                                id
                                fullpath
                            }
                            temporaryPicture {
                                id
                                fullpath
                            }
                            temporarySectionTitle
                            temporarySectionText
                            temporaryButtonTitle
                            temporaryURL
                            ShowOnHomepage
                            blogURL
                            blogsectiontitle
                            id
                            onsproces {
                                ... on PIM_object_Page {
                                    id
                                    pageIntroTitle
                                }
                            }
                            onsprocesURL
                            onsprocessection2ndtext
                            onsprocessectiontext
                            onsprocessectiontitle
                            overonsURL
                            overonssectiontext
                            overonssectiontitle
                            overonsButtontitle
                            overonsPicture {
                                                id
                                                fullpath
                                            }
                            pagesubtitle
                            pagetitle
                            projects {
                                ... on PIM_object_Project {
                                    id
                                    previewimage {
                                        id
                                        fullpath
                                    }
                                    projecttitle
                                    projectsubtitle
                                }
                                ... on PIM_object_Projectnew {
                                  id
                                  previewimage {
                                    id
                                    fullpath
                                  }
                                  projecttitle
                                  projectsubtitle
                                }
                            }
                            projectsURL
                            projectsectiontitle
                            testimonial {
                                ... on PIM_object_Testimonial {
                                    id
                                    previewimage {
                                        id
                                        fullpath
                                    }
                                    title
                                    name
                                    occupation
                                    portrait {
                                        id
                                        fullpath
                                    }
                                    testimonial
                                }
                            }
                            testimonialsURL
                            testimonialsectiontitle
                            vimeoid
                                id
                            technologies {
                                ... on PIM_object_TechnologyLink {
                                id
                                techLogo {
                                    id
                                    filename
                                    fullpath
                                }
                                techName
                                partnerPageUrl
                                techURL
                                techNewtab
                                }
                            }
                            reviews {
                                ... on PIM_object_Review {
                                id
                                review
                                reviewerName
                                reviewerNameSubline
                                reviewerPhoto {
                                    fullpath
                                    id
                                }
                                reviewerRatings
                                }
                            }
                        }
                    }
                }
                getNewsConfigListing(defaultLanguage: "auto") {
                    edges {
                        node {
                            featuredposts {
                                ... on PIM_object_NewsPost {
                                    id
                                    Author
                                    Title
                                    Tags {
                                        ... on PIM_object_Tag {
                                            id
                                            Tag
                                        }
                                    }
                                    cardPreviewImage {
                                        id
                                        fullpath
                                    }
                                    IntroText
                                }
                            }
                        }
                    }
                }
                getHomeConfig(id: 157, defaultLanguage: "auto") {
                    ecommerceButtontxt
                    ecommerceURL
                    databeheerButtontxt
                    databeheerURL
                    webinarButtontxt
                    webinarURL

                    onzeklantensectiontitle
                    onzeklantenButtontitle
                    onzeklantenURL
                    hetteamsectiontitle
                    hetteamsectiontext
                    hetteamButtontitle1
                    jobsURL
                    hetteamPicture1 {
                      id
                      fullpath
                    }
                    downloadURL
                    downloadCalltoactionTxt
                    downloadButtontitle
                    reviewShowOnHomepage
                    technologiesShowOnHomepage
                    downloadShowOnHomePage
                }

                getReferencesConfig(id: 39, defaultLanguage: "auto") {
                    customers {
                        ... on PIM_object_CustomerLink {
                            customer
                                logo {
                                    id
                                    fullpath
                                }
                        }
                    }
                }
            }
        }
    `);

    //nieuwe showOnHomePage selectors
    const { reviewShowOnHomepage, technologiesShowOnHomepage, downloadShowOnHomePage } = pim.getHomeConfig;

    //header
    const { pagetitle, pagesubtitle } = pim.getHomeConfigListing.edges[0].node;
    const { ecommerceButtontxt, ecommerceURL, databeheerButtontxt, databeheerURL, webinarButtontxt, webinarURL } = pim.getHomeConfig;

    //Temporary article
    const { temporaryPicture, temporaryURL, temporarySectionTitle, temporarySectionText, temporaryButtonTitle, ShowOnHomepage } = pim.getHomeConfigListing.edges[0].node;

    //Temporary acticle 2
    const { temporaryPicture2, temporaryURL2, temporarySectionTitle2, temporarySectionText2, temporaryButtonTitle2, ShowOnHomepage2 } = pim.getHomeConfigListing.edges[0].node;

    //over ons section
    const {
        overonsURL,
        overonssectiontext,
        overonssectiontitle,
        overonsPicture,
        overonsButtontitle,
    } = pim.getHomeConfigListing.edges[0].node;

    //project
    const {
        projectsectiontitle,
        projectsURL,
        projects,
        stucturedData
    } = pim.getHomeConfigListing.edges[0].node;

    //slider
    const { onzeklantensectiontitle, onzeklantenButtontitle, onzeklantenURL } = pim.getHomeConfig;
    let customerObjects = pim.getReferencesConfig.customers;

    //downloadCTA

    const { downloadURL, downloadCalltoactionTxt, downloadButtontitle } = pim.getHomeConfig;

    const downloadData = { text: downloadCalltoactionTxt, buttonTxt: downloadButtontitle, buttonUrl: downloadURL }

    let imageObjects = customerObjects.map((c) => {
        return { text: c.customer, ...c.logo }
    });

    const { technologies } = pim.getHomeConfigListing.edges[0].node;

    //Het team
    const { hetteamsectiontitle, hetteamsectiontext, hetteamButtontitle1, jobsURL, hetteamPicture1 } = pim.getHomeConfig;

    const isEcommerceBtn = checkButton(ecommerceButtontxt, ecommerceURL);
    const isDatabeheerBtn = checkButton(databeheerButtontxt, databeheerURL);
    const isWebinarBtn = checkButton(webinarButtontxt, webinarURL);

    //Reviews

    const { reviews } = pim.getHomeConfigListing.edges[0].node;
    let reviewData = [];

    //When reviews exists, without this check, the .length would break code
    if (reviews != null) {
        for (let index = 0; index < reviews.length; index++) {
            let item = reviews[index];
            const obj = { id: index, stars: item['reviewerRatings'], text: item['review'], title: item['reviewerName'], subtitle: item['reviewerNameSubline'], image: { placeholder: "/assets/placeholders/review.jpg", ...item['reviewerPhoto'] } };
            reviewData.push(obj);
        }
    }

    return (
        <>
            <ServerSideMetaTags pim={pim} metadata={metadata} structureddata={stucturedData} />

            <ContentWrapper isWide={true}>

                <Header image={false} size="oneLiner">

                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                    >
                        <BubblesBg
                            active={false} />
                        <FloatingIcons
                            active={false}
                        />

                        <ContentWrapper
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >

                            {/* NON WYSIWYG feature */}

                            <Heading
                                isSemantic={true}
                                variant="h1"
                                color="white"
                            >
                                <div className="" dangerouslySetInnerHTML={{ __html: pagetitle }} />
                            </Heading>
                            {/* <HighlightedText color="white"> */}
                            <div className={css.subContainer} dangerouslySetInnerHTML={{ __html: pagesubtitle }} />
                            {/* </HighlightedText> */}

                            {/* END NONE WYSIWYG feature */}

                            {(isEcommerceBtn || isDatabeheerBtn || isWebinarBtn) && (
                                <LinkButtonRow>
                                    {isEcommerceBtn && (
                                        <LinkButton
                                            link={urlBuilder.buildStaticUrl(ecommerceURL)}
                                            variant="orangeRevert"
                                            text={ecommerceButtontxt}
                                        />
                                    )}

                                    {isDatabeheerBtn && (
                                        <LinkButton
                                            link={urlBuilder.buildStaticUrl(databeheerURL)}
                                            variant="orangeRevert"
                                            text={databeheerButtontxt}
                                        />
                                    )}

                                    {isWebinarBtn && (
                                        <LinkButton
                                            link={urlBuilder.buildStaticUrl(webinarURL)}
                                            variant="orangeRevert"
                                            text={webinarButtontxt}
                                        />
                                    )}
                                </LinkButtonRow>
                            )}
                        </ContentWrapper>
                    </ContentWrapper>
                </Header>
                {/* partner scrolling */}
                {technologiesShowOnHomepage && (
                    <ContentWrapper
                        maxWidth="maxWidthMax"
                    >
                        <GenericSlider width="fixed">
                            { technologies.map(item => {
                                return (
                                    <PartnerSliderItem
                                        key={item.id}
                                        id={item.id}
                                        partnerPageUrl={item.partnerPageUrl}
                                        techLogo={item.techLogo}
                                        techNewtab={item.techNewtab}
                                        text={item.techName}
                                    />
                                )
                            }) }
                        </GenericSlider>
                    </ContentWrapper>
                )}

                {/* end partner scrolling */}
                {ShowOnHomepage
                    ? <Fade duration={2000}>
                        <ContentWrapper paddedTop paddedBottom>
                            <Article
                                title={temporarySectionTitle}
                                text={temporarySectionText}
                                mediaSrc={temporaryPicture}
                                type="image"
                            >
                                <LinkButton
                                    variant="blue"
                                    text={temporaryButtonTitle}
                                    link={urlBuilder.buildStaticUrl(temporaryURL)}
                                    uppercase={false}
                                />
                            </Article>
                        </ContentWrapper></Fade>
                    : ""}
                {ShowOnHomepage2
                    ? <Fade duration={2000}>
                        <ContentWrapper paddedTop paddedBottom>
                            <Article
                                title={temporarySectionTitle2}
                                text={temporarySectionText2}
                                mediaSrc={temporaryPicture2}
                                type="image"
                                reverse
                            >
                                <LinkButton
                                    variant="blue"
                                    text={temporaryButtonTitle2}
                                    link={urlBuilder.buildStaticUrl(temporaryURL2)}
                                    uppercase={false}
                                />
                            </Article>
                        </ContentWrapper></Fade> : ""}
                <Fade duration={2000}>

                    <ContentWrapper paddedTop paddedBottom maxWidth="maxWidthMedium">
                        <FeaturedServiceGrid />
                    </ContentWrapper></Fade>
                <Fade duration={2000}>
                    <ContentWrapper paddedTop paddedBottom>
                        <Article
                            title={overonssectiontitle}
                            text={overonssectiontext}
                            mediaSrc={overonsPicture}
                            type="image"
                        >
                            <LinkButton
                                variant="blue"
                                text={overonsButtontitle}
                                link={urlBuilder.buildStaticUrl(overonsURL)}
                                uppercase={false}
                            />
                        </Article>
                    </ContentWrapper></Fade>
                <Fade duration={2000}>
                    <ContentWrapper paddedTop paddedBottom textCenter slider>
                        <Heading
                            isSemantic={true}
                            variant="h2"
                        >
                            {onzeklantensectiontitle}
                        </Heading>
                        <ImageSlider imageObjects={imageObjects} imageTitles width="fixed">
                            <LinkButton
                                variant="blue"
                                text={onzeklantenButtontitle}
                                link={urlBuilder.buildStaticUrl(onzeklantenURL)}
                                uppercase={false}
                            />
                        </ImageSlider>
                    </ContentWrapper > </Fade>
                <Fade duration={2000}>
                    <ContentWrapper paddedTop paddedBottom>
                        <ServicesGrid />
                    </ContentWrapper> </Fade>
                {downloadShowOnHomePage && (
                    <Fade duration={2000}>
                        <ContentWrapper paddedTop paddedBottom>
                            <DownloadCta {...downloadData} />
                        </ContentWrapper>
                    </Fade>
                )}

                <Fade duration={2000}>
                    <ContentWrapper paddedTop paddedBottom>
                        <h2 className="u-centered">{projectsectiontitle}</h2>
                        <CardGrid>
                            {projects.map((project) => (
                                <Card
                                    key={project.id}
                                    title={project.projecttitle}
                                    subtitle={project.projectsubtitle}
                                    url={urlBuilder.buildDynamicUrl(
                                        project.id,
                                    )}
                                    image={generateThumbnailUrl(
                                        project.previewimage,
                                        "card_preview_thumbnail"
                                    )}
                                />
                            ))}
                        </CardGrid>
                        <div className="u-centered">
                            <LinkButton
                                variant="blue"
                                text={__("All references")}
                                link={urlBuilder.buildStaticUrl(projectsURL)}
                                uppercase={false}
                            />
                        </div>
                    </ContentWrapper> </Fade>
                <Fade duration={2000}>
                    <ContentWrapper paddedTop paddedBottom>
                        <Article
                            title={hetteamsectiontitle}
                            text={hetteamsectiontext}
                            mediaSrc={hetteamPicture1}
                            type="image"
                        >
                            <LinkButton
                                variant="blue"
                                text={hetteamButtontitle1}
                                link={urlBuilder.buildStaticUrl(jobsURL)}
                                uppercase={false}
                            />
                        </Article>
                    </ContentWrapper> </Fade>
            </ContentWrapper>
            {reviewShowOnHomepage && (
                <ContentWrapper paddedBottom paddedTop>
                    <Fade duration={2000}>
                        <ReviewFader data={reviewData} />
                    </Fade>
                </ContentWrapper>
            )}

        </>
    );
};

const IndexPage = () => {
    return <HomeQuery />;
};

export default IndexPage;
