/* eslint-disable jsx-a11y/label-has-for */

import React, { Component } from "react";
import css from "./ContactInformation.module.scss";


class ContactInformation extends Component {
    render() {
        const { items } = this.props;

        return (
            <section className={css.container}>
                <div className={css.itemWrapper}>
                    {items.map(({ label, text, value }, index) => {
                        return (
                            <div key={index} className={css.item}>
                                <label>{label}</label>
                                <a className="" href={value}>
                                    {text}
                                </a>
                            </div>
                        );
                    })}
                </div>
            </section>
        );
    }
}

export default ContactInformation;
