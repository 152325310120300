import React from "react";
import { setMeta } from "@redux/actions";
import { connect } from "react-redux";
import { store } from '@redux/provider';


export const parseMeta = (data, options) => {
    if (options === undefined || options === null) return;

    const {
        query,
        title,
        description,
        image,
        structureddata
    } = options;

    if (query === null || query === undefined || query.includes('PLACEHOLDER')) return; // temp

    const isListingQuery = query.includes('Listing');

    const queryResult = isListingQuery
                            ? Array.isArray(data.pim[query].edges) && data.pim[query].edges.length > 0 ? data.pim[query].edges[0].node : null
                            : data.pim[query];

    if(typeof document !== undefined && queryResult){
        let structuredDataJson = queryResult[structureddata]
        if(structuredDataJson){       
        structuredDataJson = structuredDataJson.replace(new RegExp('<script[^>]*>'), '')
        structuredDataJson = structuredDataJson.replace(new RegExp('<.script>' ), '')} 
        else {structuredDataJson = null}
        
        const meta = {
            title: queryResult[title],
            description: queryResult[description],
            image: queryResult[image],
            structureddata: structuredDataJson
        }; 

        return meta;
    }
}

export const parseServerSideMeta = (pim, options) => {
    const data = { pim: pim };

    return parseMeta(data, options);
}

export const parseAndDispatchMeta = (data, options) => {
    const meta = parseMeta(data, options);

    store.dispatch(setMeta(meta));
}

export const dispatchMeta = (WrappedComponent, {
    query,
    title,
    description,
    image,
    structureddata
}) => {
    const component = class extends React.Component {
        render() {
            const { data } = this.props;

            if (query) {
                parseAndDispatchMeta(data, {
                    query,
                    title,
                    description,
                    image,
                    structureddata
                });
            }

            return <WrappedComponent {...this.props} />;
        }
    };
    return connect()(component);
};
