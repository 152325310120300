
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Article from "@components/common/Article/Article";
import ContentWrapper from "@components/common/ContentWrapper/ContentWrapper";
import FeaturedService from "@components/services/FeaturedService/FeaturedService";
import Header from "@components/common/Header/Header";
import Heading from "@components/common/Heading/Heading";
import HighlightedText from "@components/common/HighlightedText/HighlightedText";
import LinkButton from "@components/common/LinkButton/LinkButton";
import LinkButtonRow from "@components/common/LinkButtonRow/LinkButtonRow";
import urlBuilder from "@util/url-builder";
import css from "@styles-pages/services.module.scss";
import { checkButton } from '@util/check-button';
import { ServerSideMetaTags } from "../components/util/SOE/SOE";
import Fade from '@components/util/Fade';



/* eslint-disable no-undef */
export const metadata = {
    query: 'getServicesConfigListing',
    title,
    description,
    image,
    structureddata: "stucturedData"
};

const FeaturedServicesSection = ({ featuredServices }) => (
    <ContentWrapper paddedBottom={true} isWide={true}>
        <div className={css.featuredContainer}>
            {featuredServices.map((service) => (
                <FeaturedService
                    key={service.id}
                    title={service.title}
                    listingtext={service.listingtext}
                    logo={service.logo}
                    url={urlBuilder.buildStaticUrl(service.URL)}
                />
            ))}
        </div>
    </ContentWrapper>
);

const ServicesQuery = () => {
    const { pim } = useStaticQuery(graphql`
        query {
            pim {
                getServicesConfigListing(defaultLanguage: "auto") {
                    edges {
                        node {
                            title
                            subtitle
                            seotitle
                            seodescription
                            ogimage {
                                id
                                fullpath
                            }
                            button1URL
                            button1txt
                            button2URL
                            button2txt
                            featuredservices {
                                ... on PIM_object_FeaturedService {
                                    id
                                    listingtext
                                    logo {
                                        id
                                        fullpath
                                    }
                                    URL
                                    pagecontent
                                    seodescription
                                    seotitle
                                    stucturedData
                                    subtitle
                                    title
                                    project {
                                        ... on PIM_object_Project {
                                            id
                                            previewimage {
                                                id
                                                fullpath
                                            }
                                            projecttitle
                                        }
                                        ... on PIM_object_Projectnew {
                                            id
                                            previewimage {
                                                id
                                                fullpath
                                            }
                                            projecttitle
                                        }
                                    }
                                }
                            }
                            otherservicestitle
                            otherservicessubtitle
                            otherservicesbuttontitle
                            OtherservicesURL
                            otherservicesPicture {
                              id
                              fullpath
                            }
                        }
                    }
                }
            }
        }
    `);

    //Page header
    const { title, subtitle } = pim.getServicesConfigListing.edges[0].node;

    //Featured services
    const featuredServices =
        pim.getServicesConfigListing.edges[0].node.featuredservices;

    //Other services
    const {
        otherservicestitle,
        otherservicessubtitle,
        otherservicesPicture,
        button1URL,
        button1txt,
        button2URL,
        button2txt,
        stucturedData
    } = pim.getServicesConfigListing.edges[0].node;

    const isBtn1 = checkButton(button1txt, button1URL);
    const isBtn2 = checkButton(button2txt, button2URL);

    return (
        <>
            <ServerSideMetaTags pim={pim} metadata={metadata} structureddata={stucturedData}/>
            <ContentWrapper isWide={true}>
                <Header image={true} size="oneLiner">
                    <ContentWrapper
                        isWide={false}
                        className={css.contentWrapper}
                        vertical="center"
                    >
                        <ContentWrapper
                            className={css.introduction}
                            isWide={true}
                            maxWidth="maxWidthSmall"
                        >
                            <Heading
                                isSemantic={true}
                                variant="h1"
                                color="white"
                            >
                                {title}
                            </Heading>
                            <HighlightedText color="white">
                                {subtitle}
                            </HighlightedText>

                            {(isBtn1 || isBtn2) && (
                                <LinkButtonRow>
                                    {isBtn1 && (
                                        <LinkButton
                                            link={urlBuilder.buildStaticUrl(button1URL)}
                                            variant="orangeRevert"
                                            text={button1txt}
                                        />
                                    )}

                                    {isBtn2 && (
                                        <LinkButton
                                            link={urlBuilder.buildStaticUrl(button2URL)}
                                            variant="orangeRevert"
                                            text={button2txt}
                                        />
                                    )}
                                </LinkButtonRow>
                            )}
                        </ContentWrapper>
                    </ContentWrapper>
                </Header>
                <Fade duration={2000}>
                    <ContentWrapper>
                        <FeaturedServicesSection
                            featuredServices={featuredServices}
                        />
                    </ContentWrapper></Fade>
                <Fade duration={2000}>
                    <ContentWrapper paddedTop paddedBottom>
                        <Article
                            reverse
                            title={otherservicestitle}
                            text={otherservicessubtitle}
                            mediaSrc={otherservicesPicture}
                            type="image"
                        >
                        </Article>
                    </ContentWrapper></Fade>
            </ContentWrapper>
        </>
    );
};

const Page = () => {
    return <ServicesQuery />;
};

export default Page;
