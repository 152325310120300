import React, { Component } from "react";
import css from "./Benefits.module.scss";
import { __ } from "@i18n/util";

import Heading from "@components/common/Heading/Heading";
import RichContent from "@components/common/RichContent/RichContent";

const Benefits = (props) => {
    return (
        <>
            <div className={css.benefits} id={props.anchor}>
                <div className={css.benefitsContainer}>
                    <Heading isSemantic={true} variant="h2" className={css.benefitsTitle}>{props.title}</Heading>
                    <RichContent className={css.benefitsText}>
                        {props.text}
                    </RichContent>
                </div>
            </div>
        </>
    );
};

export default Benefits;