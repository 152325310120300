import React, { Fragment, Component } from "react";
import { LocalizedLink as Link } from "gatsby-theme-i18n";

import css from "./Dropdown.module.scss";

import { setIsMobileNavigationOpen as setIsMobileNavigationOpenAction } from "@redux/actions";
import { setIsStickyNavigationOpen as setIsStickyNavigationOpenAction } from "@redux/actions";
import { connect } from "react-redux";

export class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IsDropdownOpen: false,
        };
    }

    toggleList = () => {
        this.setState((prevState) => ({
            IsDropdownOpen: !prevState.IsDropdownOpen,
        }));
    };

    closeList = () => {
        this.setState({
            IsDropdownOpen: false,
        });
    };

    openList = () => {
        if (!this.state.IsDropdownOpen) {
            this.setState({
                IsDropdownOpen: true,
            });
        }
    };

    render() {
        const { IsDropdownOpen } = this.state;

        const DropdownLabel = () => {
            return (
                <Fragment>
                    {this.props.headerTitle}
                    <img
                        className={`${css.img}`}
                        alt="arrowLogo"
                        src={
                            this.props.isStickyNavigationOpen
                                ? typeof document !== undefined &&
                                (IsDropdownOpen ||
                                    document.URL.substring(document.URL.lastIndexOf('/') + 1) === ("diensten")
                                    ? "/assets/icons/down-arrow-blue.svg"
                                    : "/assets/icons/down-arrow-black.svg")
                                : "/assets/icons/down-arrow.svg"
                        }
                    />
                </Fragment>
            )
        }

        // Note: we use the global css class 'group-anchor' since we have to call it inside the Menu.module.scss
        return (
            <div onMouseLeave={this.closeList}>
                {
                    this.props.headerUrl && this.props.headerUrl !== ''
                        ? (<Link onMouseOver={this.openList} to={this.props.headerUrl}>
                            {this.props.headerTitle}
                            <img
                                className={`${css.img}`}
                                alt="arrowLogo"
                                src={
                                    this.props.isStickyNavigationOpen
                                        ? typeof document !== undefined &&
                                        (IsDropdownOpen ||
                                            document.URL.substring(document.URL.lastIndexOf('/') + 1) === ("diensten")
                                            ? "/assets/icons/down-arrow-blue.svg"
                                            : "/assets/icons/down-arrow-black.svg")
                                        : "/assets/icons/down-arrow.svg"
                                }
                            /> </Link>)
                        : (<span className={'group-anchor'} onMouseOver={this.openList} onClick={this.toggleList}>
                            {this.props.headerTitle}
                            <img
                                className={`${css.img}`}
                                alt="arrowLogo"
                                src={
                                    this.props.isStickyNavigationOpen
                                        ? typeof document !== undefined &&
                                        (IsDropdownOpen ||
                                            document.URL.substring(document.URL.lastIndexOf('/') + 1) === ("diensten")
                                            ? "/assets/icons/down-arrow-blue.svg"
                                            : "/assets/icons/down-arrow-black.svg")
                                        : "/assets/icons/down-arrow.svg"
                                }
                            />
                        </span>)
                }
                {IsDropdownOpen && (
                    <div className={`${css.elements}`}>
                        <ul>
                            {this.props.dropdownInfo.map((item) => (
                                <li key={item.id} className={`${css.element}`}>
                                    <Link
                                        to={item.Link}
                                        onClick={() => {
                                            this.closeList();
                                            this.props.setIsMobileNavigationOpen(
                                                false
                                            );
                                        }}
                                    >
                                        <span>{item.Name}</span>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    setIsMobileNavigationOpen: (boolean) =>
        dispatch(setIsMobileNavigationOpenAction(boolean)),
    setIsStickyNavigationOpen: (boolean) =>
        dispatch(setIsStickyNavigationOpenAction(boolean)),
});

const mapStateToProps = (globalState) => ({
    isMobileNavigationOpen: globalState.isMobileNavigationOpen,
    isStickyNavigationOpen: globalState.isStickyNavigationOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
